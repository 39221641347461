import React, { useEffect, useState } from 'react';

import { pipeline, env } from '@xenova/transformers';
import { toast } from 'react-toastify';

import incrpcorr from '@stdlib/stats-incr-pcorr';
// @ts-ignore
import calculateCorrelation from 'calculate-correlation';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register components of Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

import * as XLSX from 'xlsx';

import { v4 as uuidv4 } from 'uuid';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

const Histogram = ({ data }: any) => {
  if (data.length === 0) {
    return <div>No data available to display the histogram.</div>;
  }

  if (data.length === 1) {
    const singleValue = data[0];
    const chartData = {
      labels: [`${singleValue}`], // Use the exact value as the bin label
      datasets: [
        {
          label: 'Frequency',
          data: [1], // Frequency is 1
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Histogram (Single Data Point)',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Value',
          },
        },
        y: {
          title: {
            display: true,
            text: 'Frequency',
          },
          beginAtZero: true, // Ensure the y-axis starts at 0
        },
      },
    };

    return <Bar data={chartData} options={chartOptions} />;
  }

  // Compute the likelihood using Knuth's method
  const computeLikelihood = (data: number[], bins: number) => {
    const min = Math.min(...data);
    const max = Math.max(...data);
    const binWidth = (max - min) / bins;

    const frequencies = Array(bins).fill(0);
    data.forEach((value) => {
      const binIndex = Math.min(
        bins - 1,
        Math.floor((value - min) / binWidth) // Avoid overflow
      );
      frequencies[binIndex]++;
    });

    const n = data.length;
    const meanCount = n / bins;

    // Compute the likelihood formula
    const sumSquared = frequencies.reduce((acc, f) => acc + f * f, 0);
    return n * Math.log(bins) + bins * Math.log(binWidth) - sumSquared / (2 * meanCount);
  };

  // Find the optimal number of bins
  const findOptimalBins = (data: number[]) => {
    const maxBins = Math.min(50, data.length); // Limit to 50 bins for performance
    let bestBins = 1;
    let bestLikelihood = -Infinity;

    for (let bins = 1; bins <= maxBins; bins++) {
      const likelihood = computeLikelihood(data, bins);
      if (likelihood > bestLikelihood) {
        bestLikelihood = likelihood;
        bestBins = bins;
      }
    }

    return bestBins;
  };

  // Compute the optimal number of bins
  const optimalBins = findOptimalBins(data);

  // Create histogram data
  const min = Math.min(...data);
  const max = Math.max(...data);
  const binWidth = (max - min) / optimalBins;
  const bins = Array.from({ length: optimalBins }, (_, i) => min + i * binWidth);
  const frequencies = Array(optimalBins).fill(0);
  data.forEach((value: any) => {
    const binIndex = Math.min(optimalBins - 1, Math.floor((value - min) / binWidth));
    frequencies[binIndex]++;
  });

  // Prepare chart data
  const chartData = {
    labels: bins.map((bin, i) => `${bin.toFixed(3)}-${(bin + binWidth).toFixed(3)}`),
    datasets: [
      {
        label: 'Frequency',
        data: frequencies,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Pearson correlations distribution (Knuth's Bayesian Method)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Bins',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Frequency',
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

const ProgressBar = ({ progress, setProgress, startStatus }: any) => {
  return (
    <div className="w-full max-w-xs mx-auto mt-10">
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
              Progress
            </span>
          </div>
          {startStatus && (
            <div className="flex justify-center items-center space-x-2">
              <div className="w-3 h-3 bg-pink-500 animate-spin rounded-sm"></div>
              <div className="w-3 h-3 bg-pink-500 animate-spin delay-150 rounded-sm"></div>
              <div className="w-3 h-3 bg-pink-500 animate-spin delay-300 rounded-sm"></div>
            </div>
          )}
          <div className="text-right">
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
              {progress}%
            </span>
          </div>
        </div>
        <div className="flex mb-2 items-center justify-between">
          <div className="relative pt-1 w-full">
            <div className="flex mb-2 items-center justify-between">
              {/* Container for the progress bar with grey background */}
              <div className="w-full bg-gray-300">
                {/* Dynamic progress bar with teal color */}
                <div
                  className="bg-teal-500"
                  style={{
                    width: `${progress}%`, // Dynamic width based on progress
                    height: '20px',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Test3() {
  const [jsonlData, setJsonlData] = useState<any>([]);
  const [inputUploadError, setInputUploadError] = useState('');
  const [fileName, setFileName] = useState('');

  const [startStatus, setStartStatus] = useState(false);

  const [progress, setProgress] = useState(0);

  const [histogramId, setHistogramId] = useState<any>(uuidv4());

  const [samplingData, setSamplingData] = useState<any>(null);

  const [numRows, setNumRows] = useState(30);

  const [numRowsError, setNumRowsError] = useState<any>(null);

  const [numSamplings, setNumSamplings] = useState(1);

  const [numSamplingsError, setNumSamplingsError] = useState('');

  const [avgPearsonCorrelation, setAvgPearsonCorrelation] = useState<any>(0);

  const [showPC, setShowPC] = useState<any>(false);

  const handleFileUpload = (event: any) => {
    try {
      const file = event.target.files[0]; // Get the uploaded file

      if (!file) {
        setInputUploadError('Please upload a valid JSONL file.');
        return;
      }

      setFileName(file.name); // Display the uploaded file name
      const reader = new FileReader();

      reader.onload = (e: any) => {
        try {
          const fileContent = e.target.result; // Raw file content
          const lines = fileContent.trim().split('\n'); // Split into lines
          const parsedData = lines.map((line: any) => JSON.parse(line)); // Parse JSONL
          setJsonlData(parsedData); //.slice(1, 10)); // Save parsed data to state
          setInputUploadError(''); // Clear any previous errors
        } catch (err) {
          console.error('Error parsing JSONL file:', err);
          setInputUploadError('Invalid JSONL file format. Please check your file content.');
        }
      };

      reader.onerror = () => {
        console.error('Error reading file.');
        setInputUploadError('Failed to read the file. Please try again.');
      };

      reader.readAsText(file); // Read the file as text
    } catch (err) {
      console.error('Unexpected error during file upload:', err);
      setInputUploadError('An unexpected error occurred during file upload. Please try again.');
    }
  };

  const findSimilarByallMiniLML6v2 = async (data1: any, data2: any) => {
    try {
      console.log('findSimilarByallMiniLML6v2');
      // Create a feature-extraction pipeline with the specified model
      let extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');

      // Function to compute cosine similarity between two vectors
      const cosineSimilarity = (vectorA: any, vectorB: any) => {
        const dotProduct = vectorA.reduce(
          (acc: any, val: any, i: any) => acc + val * vectorB[i],
          0
        );
        const magnitudeA = Math.sqrt(vectorA.reduce((acc: any, val: any) => acc + val * val, 0));
        const magnitudeB = Math.sqrt(vectorB.reduce((acc: any, val: any) => acc + val * val, 0));
        return dotProduct / (magnitudeA * magnitudeB);
      };

      // Function to compute similarity between two sentences
      const computeSimilarity = async (sentence1: any, sentence2: any) => {
        // Compute sentence embeddings for both sentences
        const embeddings = await extractor([sentence1, sentence2], {
          pooling: 'mean',
          normalize: true,
        });

        // Calculate cosine similarity between the embeddings
        const similarityScore = await cosineSimilarity(
          embeddings.tolist()[0],
          embeddings.tolist()[1]
        );
        return similarityScore;
      };

      let score = await computeSimilarity(data1, data2);
      if (extractor && extractor?.model) {
        // Assuming the extractor has a model property that can be disposed of
        extractor.model.dispose(); // Free GPU memory or release any large resources
      }

      return score;
    } catch (e) {
      return e;
    }
  };

  function convertRange0To1To0To5(number: any) {
    try {
      if (number < 0 || number > 1) {
        throw new Error('Input must be between 0 and 1.');
      }
      return number * 5;
    } catch (error: any) {
      console.error('Error:', error.message);
      return error; // Re-throw the error so it can be handled by the calling code
    }
  }

  // Sampling Without Replacement
  function getRandomItemsEfficient(array: any, count: any) {
    try {
      if (count > array.length) {
        throw new Error('Count cannot be greater than the array size');
      }

      // Create a copy of the array to avoid modifying the original
      const arrayCopy = [...array];
      const result = [];

      for (let i = 0; i < count; i++) {
        const randomIndex = Math.floor(Math.random() * arrayCopy.length);
        result.push(arrayCopy[randomIndex]); // Add the selected item
        arrayCopy.splice(randomIndex, 1); // Remove the picked item
      }

      return result;
    } catch (e) {
      return null;
    }
  }

  const isNumber = (value: any) => typeof value === 'number';

  async function startButton() {
    setProgress(0);
    setShowPC(false);
    setStartStatus(true);

    if (jsonlData?.length > 0) {
      try {
        if (numRows < 30 || numRows > jsonlData?.length) {
          setNumRowsError(
            'Please enter a number greater than 29 and less than or equal to the available rows.'
          );

          return;
        }

        if (numSamplings <= 0) {
          setNumSamplingsError('Please enter a number greater than 0');

          return;
        }

        setNumSamplingsError('');
        setNumRowsError('');

        let finalSamplingData = {} as any;
        for (let j = 0; j < numSamplings; j++) {
          // Update progress
          console.log('j+1', j + 1);
          console.log('numSamplings', numSamplings);
          console.log(
            '(((j + 1) / numSamplings) * 100).toFixed(2)',
            (((j + 1) / numSamplings) * 100).toFixed(2)
          );

          let sampleData = await getRandomItemsEfficient(jsonlData, numRows);
          if (!sampleData || sampleData?.length <= 0) {
            toast.error('get random items error');
            return;
          }

          let originalData = [];
          let calculatedData = [];

          for (let i = 0; i < sampleData?.length; i++) {
            console.log('i+1', i + 1);
            console.log('sampleData?.length', sampleData?.length);
            console.log(
              `(
                (((i + 1) + (j * numRows)) / (numSamplings * numRows)) *
                100
              ).toFixed(2);`,
              (((i + 1 + j * numRows) / (numSamplings * numRows)) * 100).toFixed(2)
            );

            let item = sampleData[i] as any;

            // Asynchronous call to find similarity
            let similarScore = (await findSimilarByallMiniLML6v2(
              item?.sentence1,
              item?.sentence2
            )) as any;

            if (isNumber(similarScore)) {
              if (isNumber(convertRange0To1To0To5(similarScore))) {
                let uniqueId = uuidv4();
                originalData.push({ uniqueId, ...item });

                calculatedData.push({
                  uniqueId,
                  ...item,
                  modelScore: convertRange0To1To0To5(similarScore),
                });
              }
            }

            let currentProgress = (
              ((i + 1 + j * numRows) / (numSamplings * numRows)) *
              100
            ).toFixed(2);
            setProgress(currentProgress as any);
          }
          let finalDataToAdd = {
            uniqueId: uuidv4(),
            originalData,
            calculatedData,
            pearsonCorrelation: isNumber(calculatePearsonCorrelation(originalData, calculatedData))
              ? calculatePearsonCorrelation(originalData, calculatedData)
              : null,
          };

          finalSamplingData[j] = finalDataToAdd;
        }
        console.log('finalSamplingData', finalSamplingData);

        setSamplingData(finalSamplingData);
      } catch (error) {
        // Catch any error that occurs during the loop or asynchronous operation
        console.error('Error processing data:', error);
        toast.error('데이터 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
      } finally {
        setStartStatus(false);
      }
    } else {
      toast.error('데이터를 업로드해주세요');
    }

    setStartStatus(false);
  }

  const calculateAvgPearsonCorrelation = (objectData: any) => {
    try {
      let sumPCs = 0;
      let numOfObjectsUsed = 0;
      for (let i = 0; i < Object.keys(objectData)?.length; i++) {
        let keyData = Object.keys(objectData)[i] as any;

        if (isNumber(objectData[parseInt(keyData)]?.pearsonCorrelation)) {
          sumPCs = sumPCs + objectData[parseInt(keyData)]?.pearsonCorrelation;
          numOfObjectsUsed++;
        }
      }
      console.log('sumPCs / numOfObjectsUsed', sumPCs / numOfObjectsUsed);
      return sumPCs / numOfObjectsUsed;
    } catch (e) {
      console.log('calculateAvgPearsonCorrelation', e);
      return null;
    }
  };

  const calculatePearsonCorrelation = (originalData: any, calculatedData: any) => {
    try {
      const humanScores = originalData.map((item: any) => item.score);
      const predictedScores = calculatedData.map((item: any) => item?.modelScore);

      // Use @stdlib/stats-incr-pcorr library
      const incr = incrpcorr(); // Initialize the incremental Pearson correlation function

      // Compute the Pearson correlation incrementally
      predictedScores.forEach((predicted: any, index: any) => {
        incr(predicted, humanScores[index]); // Update the correlation with each pair
      });

      const correlation = incr(); // Get the final correlation result

      // Use calculate-correlation library
      const correlation2 = calculateCorrelation(humanScores, predictedScores);

      console.log('correlation', correlation);
      console.log('correlation2', correlation2);

      return correlation;
    } catch (error: any) {
      console.error('Error calculating Pearson correlation:', error.message);
      return null; // Return null or handle the error as needed
    }
  };

  const downloadExcel = (sheetsData: { sheetName: string; data: any[] }[], excelName: string) => {
    try {
      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Loop through the provided sheets data and append each as a sheet
      sheetsData.forEach((sheet) => {
        // Convert the data to a worksheet
        const ws = XLSX.utils.json_to_sheet(sheet.data);
        // Append the sheet to the workbook with the specified sheet name
        XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);
      });

      // Generate Excel file and trigger download
      XLSX.writeFile(wb, `${excelName}.xlsx`);
    } catch (error: any) {
      console.error('Error generating or downloading Excel file:', error.message);
      // Optionally, handle error by showing an alert, or set an error state
    }
  };

  const downloadOriginalData = async () => {
    try {
      let sheetsData = [] as any;
      for (let i = 0; i < Object.keys(samplingData)?.length; i++) {
        let keyData = Object.keys(samplingData)?.[i];
        let obtainedObj = samplingData[parseInt(keyData)];
        let stName = isNumber(obtainedObj?.pearsonCorrelation)
          ? `${
              obtainedObj?.uniqueId?.split('-')?.[0]
            }_pearson_corr_${obtainedObj?.pearsonCorrelation?.toFixed(2)}_od`
          : `${obtainedObj?.uniqueId?.split('-')?.[0]}_od`;
        sheetsData.push({
          sheetName: stName,
          data: obtainedObj?.originalData ?? [],
        });
      }
      await downloadExcel(
        sheetsData,
        fileName?.slice(0, 7) +
          '_original_data_' +
          `_average_pearson_corr_${avgPearsonCorrelation?.toFixed(2)}_` +
          new Date().toISOString()
      );
    } catch (e) {
      console.log('e', e);
      toast.error('download original data error');
    }
  };

  const downloadModelScores = async () => {
    try {
      let sheetsData = [] as any;
      for (let i = 0; i < Object.keys(samplingData)?.length; i++) {
        let keyData = Object.keys(samplingData)?.[i];
        let obtainedObj = samplingData[parseInt(keyData)];
        let stName = isNumber(obtainedObj?.pearsonCorrelation)
          ? `${
              obtainedObj?.uniqueId?.split('-')?.[0]
            }_pearson_corr_${obtainedObj?.pearsonCorrelation?.toFixed(2)}_msd`
          : `${obtainedObj?.uniqueId?.split('-')?.[0]}_msd`;
        sheetsData.push({
          sheetName: stName,
          data: obtainedObj?.calculatedData ?? [],
        });

        // originalData,
        // calculatedData,
        // f1Score
      }
      await downloadExcel(
        sheetsData,
        fileName?.slice(0, 7) +
          '_model_scores_data_' +
          `_average_pearson_corr_${avgPearsonCorrelation?.toFixed(2)}_` +
          new Date().toISOString()
      );
    } catch (e) {
      console.log('e', e);
      toast.error('download model scores data error');
    }
  };

  const getAveragePearsonCorrelation = async () => {
    try {
      let pcRes = await calculateAvgPearsonCorrelation(samplingData);
      setAvgPearsonCorrelation(pcRes);
      setShowPC(true);
    } catch (e) {
      console.log('getAveragePearsonCorrelation', e);
      toast.error('get average pearson correlation error');
    }
  };

  const convertSamplingDataForHistgram = (givenSamplingData: any) => {
    try {
      let finalList = [];
      for (let i = 0; i < Object.keys(givenSamplingData)?.length; i++) {
        let keyData = Object.keys(givenSamplingData)[i] as any;
        let obtainedObj = givenSamplingData[parseInt(keyData)];
        if (obtainedObj?.pearsonCorrelation) {
          finalList.push(obtainedObj?.pearsonCorrelation);
        }
      }
      return finalList;
    } catch (e) {
      console.log('convertSamplingDataForHistgram', e);
      return null;
    }
  };

  return (
    <>
      <div className="p-6 max-w-3xl mx-auto bg-white  ">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">평가항목(성능지표):</h2>
          <div className="mt-4 space-y-3 text-gray-600">
            <p>Vision Encoder Decoder 기반의 이미지 분석 및 검색 시스템 정확도</p>
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-semibold text-gray-800">평가방법:</h2>
          <div className="mt-4 space-y-3 text-gray-600">
            <p>
              자사 모델을 활용하여 STS Benchmark에서 얻은 pearson correlation을 목표치 (0.929)와
              비교
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">JSONL File을 업로드해주세요</h2>

        {/* File Upload Area */}
        <div
          className={`relative border-2 border-dashed rounded-lg p-6 cursor-pointer ${
            startStatus
              ? 'border-gray-400 bg-gray-200 cursor-not-allowed opacity-60'
              : 'border-gray-300 hover:border-blue-400'
          }`}
        >
          <input
            id="file-input"
            type="file"
            accept=".jsonl"
            onChange={handleFileUpload}
            className={`absolute inset-0 w-full h-full opacity-0 ${
              startStatus ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            disabled={startStatus}
          />
          <div className="text-center">
            {fileName ? (
              <p className="text-gray-700 font-medium">{fileName}</p>
            ) : (
              <p className="text-gray-500">
                Drag & drop your file here or <span className="text-blue-600">click to browse</span>
              </p>
            )}
          </div>
        </div>

        {/* Error Message */}
        {inputUploadError && <p className="text-red-600 font-medium mt-4">{inputUploadError}</p>}
      </div>

      {jsonlData?.length > 0 ? (
        <>
          <div className="max-w-2xl mx-auto p-6 flex gap-[20px] items-end">
            <div className="mb-6">
              <label
                htmlFor="numSamplings"
                className="font-bold block text-gray-700 font-medium mb-2 text-center"
              >
                샘플링 횟수 (Number of samplings)
              </label>

              <input
                type="number"
                id="numSamplings"
                value={numSamplings}
                onChange={(e) => setNumSamplings(Number(e.target.value))}
                min="1"
                // max={csvData?.length}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {numSamplingsError && <p className="text-red-600 mt-2">{numSamplingsError}</p>}
            </div>
            <div className="mb-6">
              <label
                htmlFor="numRows"
                className="font-bold block text-gray-700 font-medium mb-2 text-center"
              >
                샘플 크기 (Sample size)
              </label>
              <p className="text-center mb-2">
                (총 데이터 개수: {jsonlData?.length?.toLocaleString()}개)
              </p>
              <input
                type="number"
                id="numRows"
                value={numRows}
                onChange={(e) => setNumRows(Number(e.target.value))}
                min="30"
                max={jsonlData?.length}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {numRowsError && <p className="text-red-600 mt-2">{numRowsError}</p>}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[10px] justify-center">
        <button
          onClick={() => {
            startButton();
          }}
          className={`font-semibold py-2 px-4 rounded-md shadow-md transition duration-200 ease-in-out ${
            !startStatus
              ? 'bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500'
              : 'bg-gray-400 text-gray-200 cursor-not-allowed'
          }`}
          disabled={startStatus}
        >
          Start applying model
        </button>
        <button
          onClick={
            Object.keys(samplingData ?? {})?.length > 0 && !startStatus
              ? getAveragePearsonCorrelation
              : undefined
          }
          className={`font-semibold py-2 px-4 rounded-md shadow-md transition duration-200 ease-in-out ${
            Object.keys(samplingData ?? {})?.length > 0 && !startStatus
              ? 'bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500'
              : 'bg-gray-400 text-gray-200 cursor-not-allowed'
          }`}
          disabled={!(Object.keys(samplingData ?? {})?.length > 0) || startStatus}
        >
          Calculate average pearson correlation
        </button>
        <button
          onClick={
            Object.keys(samplingData ?? {})?.length > 0 && !startStatus
              ? downloadOriginalData
              : undefined
          }
          className={`font-semibold py-2 px-4 rounded-md shadow-md transition duration-200 ease-in-out ${
            Object.keys(samplingData ?? {})?.length > 0 && !startStatus
              ? 'bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500'
              : 'bg-gray-400 text-gray-200 cursor-not-allowed'
          }`}
          disabled={!(Object.keys(samplingData ?? {})?.length > 0) || startStatus}
        >
          Download Original Data (Excel)
        </button>
        <button
          onClick={
            Object.keys(samplingData ?? {})?.length > 0 && !startStatus
              ? downloadModelScores
              : undefined
          }
          className={`font-semibold py-2 px-4 rounded-md shadow-md transition duration-200 ease-in-out ${
            Object.keys(samplingData ?? {})?.length > 0 && !startStatus
              ? 'bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500'
              : 'bg-gray-400 text-gray-200 cursor-not-allowed'
          }`}
          disabled={!(Object.keys(samplingData ?? {})?.length > 0) || startStatus}
        >
          Download Model Scores (Excel)
        </button>
      </div>

      <ProgressBar progress={progress} setProgress={setProgress} startStatus={startStatus} />

      {showPC ? (
        <>
          <div className="flex justify-center mt-10">
            <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-md">
              <h2 className="text-xl font-bold mb-4 text-indigo-400">
                Average Pearson Correlation Result
              </h2>
              <div className="text-center">
                <p className="text-4xl font-extrabold text-green-400">{avgPearsonCorrelation}</p>
              </div>
            </div>
          </div>
          {histogramId && (convertSamplingDataForHistgram(samplingData) ?? [])?.length > 0 && (
            <Histogram data={convertSamplingDataForHistgram(samplingData)} />
          )}
        </>
      ) : (
        <></>
      )}

      {/* {jsonlData.length > 0 && (
      <div className="mt-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-3">Parsed JSONL Data:</h3>
        <ul className="space-y-4">
          {jsonlData.map((item, index) => (
            <li
              key={index}
              className="bg-gray-100 p-4 rounded-lg shadow-sm border border-gray-300"
            >
              <pre className="text-sm text-gray-800">{JSON.stringify(item, null, 2)}</pre>
            </li>
          ))}
        </ul>
      </div>
    )} */}
    </>
  );
}
