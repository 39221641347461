import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { api } from '../../../../../../plugins/axios';

const ExcelDownload = (props: any) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [minFollowers, setMinFollowers] = useState('');
  const [maxFollowers, setMaxFollowers] = useState('');

  const [selectedUserRegion, setSelectedUserRegion] = useState('');

  const [selectedSearchQueries, setSelectedSearchQueries] = useState<any>([]);

  const [selectedInferredUserRegions, setSelectedInferredUserRegions] = useState<any>([]);

  const [downExcelStatus, setDownExcelStatus] = useState(false);

  const handleDownload = async () => {
    setDownExcelStatus(true);
    try {
      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          alert('Start date cannot be greater than end date.');
          return;
        }
      }

      if (parseInt(minFollowers) && parseInt(maxFollowers)) {
        if (parseInt(minFollowers) > parseInt(maxFollowers)) {
          alert('Min followers cannot be greater than max followers.');
          return;
        }
      }

      const response = await api.get('/admin/tiktok/webcrape/post/download/excel', {
        params: {
          startDate: startDate,
          endDate: endDate,

          searchQueries: selectedSearchQueries.join(','),

          minFollowers,
          maxFollowers,

          userRegions: selectedInferredUserRegions.join(','),
        },
      });

      if (response?.status == 200) {
        if (response?.data?.length > 0) {
          // Modify the response data to convert 'tags' array into a string
          const modifiedData = response?.data.map((item: any) => ({
            ...item,
            tags: item.tags ? item.tags.join(', ') : '', // Join the tags array into a comma-separated string
            inferredUserEmails: item.inferredUserEmails ? item.inferredUserEmails.join(', ') : '',
          }));

          // Create a worksheet from the modified data
          const ws = XLSX.utils.json_to_sheet(modifiedData);

          // Create a new workbook with the data in a sheet
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Adding the worksheet

          // Write to Excel file and trigger download
          let fileName = `_`;
          if (startDate && endDate) {
            // If both start and end date are provided
            fileName += `${startDate}_to_${endDate}`;
          } else if (startDate) {
            // If only start date is provided
            fileName += `from_${startDate}`;
          } else if (endDate) {
            // If only end date is provided
            fileName += `until_${endDate}`;
          } else {
            // If no dates are provided, indicate entire period
            fileName += 'entire_period';
          }

          XLSX.writeFile(wb, `${fileName}.xlsx`);
        } else {
          alert('empty: no data for given conditions');
        }
      } else {
        alert('error');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setTimeout(() => {
        setDownExcelStatus(false);
      }, 1000);
    }
    // setTimeout(() => {
    //   setDownExcelStatus(false);
    // }, 1000);
  };

  const toggleSelectionForSearchQueries = (item: any) => {
    setSelectedSearchQueries(
      (prev: any) =>
        prev.includes(item)
          ? prev.filter((selected: any) => selected !== item) // Remove if already selected
          : [...prev, item] // Add if not selected
    );
  };

  const toggleSelectionForInferredUserRegions = (item: any) => {
    setSelectedInferredUserRegions(
      (prev: any) =>
        prev.includes(item)
          ? prev.filter((selected: any) => selected !== item) // Remove if already selected
          : [...prev, item] // Add if not selected
    );
  };

  return (
    <div className="border border-black p-6 bg-white rounded-lg mb-6 max-w-4xl mx-auto">
      {/* Title */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Excel Download</h2>

      {/* Form Inputs and Button */}
      <div className="space-y-6 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0">
        {/* Start Date */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* End Date */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Search Query */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">Search Query:</label>
          {/* <select
            value={selectedQuery}
            onChange={(e) => setSelectedQuery(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">-- Select a Query --</option>
            {props?.searchQueries?.map((query: any, index: any) => (
              <option key={index} value={query}>
                {query}
              </option>
            ))}
          </select> */}
          <div className="w-full">
            {selectedSearchQueries.length > 0 && (
              <div className="border rounded-md bg-[#f3e4f5] p-2 text-gray-700 shadow-md cursor-pointer">
                {selectedSearchQueries.join(', ')}
              </div>
            )}
            <div className="top-full left-0 right-0 mt-1 max-h-[150px] overflow-y-auto border rounded-md bg-white shadow-md z-10">
              {props?.searchQueries.map((item: any) => (
                <div
                  key={item}
                  onClick={() => toggleSelectionForSearchQueries(item)}
                  className={`p-2 cursor-pointer hover:bg-blue-100 ${
                    selectedSearchQueries.includes(item) ? 'bg-blue-500 text-white' : ''
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Inferred User Regions */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">User Region:</label>
          {/* <select
            value={selectedUserRegion}
            onChange={(e) => setSelectedUserRegion(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">-- Select a User Region --</option>
            {props?.inferredUserRegions?.map((ur: any, index: any) => (
              <option key={index} value={ur}>
                {ur}
              </option>
            ))}
          </select> */}

          <div className="w-full">
            {selectedInferredUserRegions.length > 0 && (
              <div className="border rounded-md bg-[#f3e4f5] p-2 text-gray-700 shadow-md cursor-pointer">
                {selectedInferredUserRegions.join(', ')}
              </div>
            )}
            <div className="top-full left-0 right-0 mt-1 max-h-[150px] overflow-y-auto border rounded-md bg-white shadow-md z-10">
              {props?.inferredUserRegions.map((item: any) => (
                <div
                  key={item}
                  onClick={() => toggleSelectionForInferredUserRegions(item)}
                  className={`p-2 cursor-pointer hover:bg-blue-100 ${
                    selectedInferredUserRegions.includes(item) ? 'bg-blue-500 text-white' : ''
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Inferred User Follower Count Range */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">Follower Count Range:</label>
          <div className="flex space-x-2">
            <input
              type="number"
              value={minFollowers}
              onChange={(e) => setMinFollowers(e.target.value)}
              placeholder="Min"
              className="block w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="number"
              value={maxFollowers}
              onChange={(e) => setMaxFollowers(e.target.value)}
              placeholder="Max"
              className="block w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Download Button */}
        <div className="flex justify-center sm:col-span-2">
          <button
            onClick={handleDownload}
            disabled={downExcelStatus}
            className={`px-6 py-3 w-full sm:w-auto font-medium text-white rounded-md shadow-md ${
              downExcelStatus
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500'
            }`}
          >
            Download Excel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExcelDownload;
