import React, { useEffect, useState } from 'react';

const DataAnalysis = ({ data }: any) => {
  const [confidenceInterval, setConfidenceInterval] = useState<[number, number] | null>(null); // Confidence Interval

  const calculateMetrics = () => {
    // Assuming 'times' is your array of measured times extracted from data
    if (data?.length <= 0) {
      alert('Upload file!');
      return;
    }

    let times = data.map((obj: any) => {
      return parseFloat(obj?.loadTime);
    });

    // Bootstrap Confidence Interval
    const nBootstrapSamples = 1000; // Number of bootstrap samples
    const bootstrapSamples = [];

    // Perform bootstrapping on times
    for (let i = 0; i < nBootstrapSamples; i++) {
      const bootstrapSample = [];
      for (let j = 0; j < times.length; j++) {
        const randomIndex = Math.floor(Math.random() * times.length);
        bootstrapSample.push(times[randomIndex]);
      }
      bootstrapSamples.push(bootstrapSample);
    }

    // Calculate means for each bootstrap sample
    const bootstrapMeans = bootstrapSamples.map((sample) => {
      const mean = sample.reduce((acc, value) => acc + value, 0) / sample.length;
      return mean;
    });

    // Sort bootstrap means
    const sortedMeans = bootstrapMeans.sort((a, b) => a - b);

    // Calculate confidence interval
    const alpha = 0.95; // Confidence level
    const lowerQuantile = (1 - alpha) / 2;
    const upperQuantile = 1 - lowerQuantile;
    const lowerIndex = Math.floor(lowerQuantile * nBootstrapSamples);
    const upperIndex = Math.floor(upperQuantile * nBootstrapSamples);
    const ci: [number, number] = [sortedMeans[lowerIndex], sortedMeans[upperIndex]];

    setConfidenceInterval(ci);
  };

  return (
    <>
      <div className="flex justify-center items-center flex-col mt-[10px]">
        {/* Button to trigger calculation */}
        <button
          onClick={calculateMetrics}
          className="bg-[black] text-[white] rounded-[7px] py-[5px] px-[5px]"
        >
          Calculate Bootstrap Confidence Interval
        </button>

        {/* Display confidence interval */}
        {confidenceInterval !== null && (
          <div>
            {/* Display Bootstrap Confidence Interval */}
            <p>
              <strong>Bootstrap Confidence Interval:</strong> [{confidenceInterval[0].toFixed(2)},{' '}
              {confidenceInterval[1].toFixed(2)}]{/* Explanation of Confidence Interval */}
              <span className="text-gray-500"></span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const Test7 = () => {
  const [data1Content, setData1Content] = useState<any>([]);
  const [data2Content, setData2Content] = useState<any>([]);
  const [data3Content, setData3Content] = useState<any>([]);

  // const handle1FileUpload = (event: any) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       try {
  //         const jsonData = JSON.parse(e.target.result);
  //         setData1Content(jsonData);
  //       } catch (error) {
  //         console.error('Error parsing JSON file:', error);
  //       }
  //       event.target.value = '';
  //     };
  //     reader.readAsText(file);
  //   }
  // };

  // const handle2FileUpload = (event: any) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       try {
  //         const jsonData = JSON.parse(e.target.result);
  //         setData2Content(jsonData);
  //       } catch (error) {
  //         console.error('Error parsing JSON file:', error);
  //       }
  //       event.target.value = '';
  //     };
  //     reader.readAsText(file);
  //   }
  // };

  const handleFileUpload = (event: any, setDataContent: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          setDataContent(jsonData.slice(-100));
        } catch (error) {
          console.error('Error parsing JSON file:', error);
        }
        event.target.value = '';
      };
      reader.readAsText(file);
    }
  };

  const modifiedContent = (content: any) => {
    try {
      if (content?.length > 0) {
        const parsedData = (data: any) => {
          try {
            return JSON.parse(data);
          } catch (e) {
            return null;
          }
        };
        let tempArr = [] as any;
        content.map((obj: any, index: any) => {
          if (obj) {
            let parsed = parsedData(obj);
            if (parsed) {
              const speedRegex = /took\s+(\d+(\.\d+)?)\s+milliseconds/;
              const match = parsed?.message?.match(speedRegex);
              if (match) {
                const speed = parseFloat(match[1]);
                parsed['time'] = speed;
              } else {
                console.log('Speed not found in the log line.');
              }
              tempArr.push({ ...parsed });
            }
          }
        });
        return tempArr;
      } else {
        return [];
      }
    } catch (e: any) {
      return null;
    }
  };

  const renderTable = (content: any) => {
    return (
      <table className="w-full">
        <thead>
          <tr>
            {/* <th>Level</th> */}
            {/* <th>Message</th> */}
            {/* <th>Timestamp</th> */}
            <th>Time (milliseconds)</th>
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 &&
            content?.map((line: any, index: any) => {
              // Parse JSON content

              return (
                <tr key={index}>
                  {/* <td>{line?.level}</td> */}
                  {/* <td>{line?.message}</td> */}
                  {/* <td>{line?.timestamp}</td> */}
                  <td className="text-center">{line?.loadTime}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="flex justify-center">
      <button
        onClick={() => {
          window.location.href = '/admin/tips/test4';
        }}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        테스트 페이지 바로가기
      </button>

      {/* <div className="grid grid-cols-3 gap-[5px] p-4">
      <div className="">
        
        <h2 className="text-lg font-semibold mb-2 text-center">original</h2>
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3V5H6a2 2 0 0 0-2 2v1H2V7a2 2 0 0 1 2-2zm2 10v3h6v-3h3a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3zm1-9h6v3H7V6zm7 5v1H6V8h8zm-4 3h2v2h-2v-2z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="mt-2">Select File</span>
          <input
            type="file"
            accept=".log, .txt, .json"
            className="hidden"
            onChange={(e) => handleFileUpload(e, setData1Content)}
          />
        </label>
        <>
          <DataAnalysis
            //  data={modifiedContent(data1Content)}
            data={data1Content}
          />
        </>
        <div className="bg-gray-100 p-4 mt-[5px] border rounded max-h-72 overflow-auto">
          {data1Content.length > 0
            ? renderTable(data1Content ?? null)
            : 'Upload a log file to view its contents here.'}
        </div>
      </div>
      <div className="">
        <h2 className="text-lg font-semibold mb-2 text-center">modification 1 - w/o observer</h2>
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3V5H6a2 2 0 0 0-2 2v1H2V7a2 2 0 0 1 2-2zm2 10v3h6v-3h3a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3zm1-9h6v3H7V6zm7 5v1H6V8h8zm-4 3h2v2h-2v-2z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="mt-2">Select File</span>
          <input
            type="file"
            accept=".log, .txt, .json"
            className="hidden"
            onChange={(e) => handleFileUpload(e, setData2Content)}
          />
        </label>
        <>
          <DataAnalysis data={data2Content} />
        </>
        <div className="bg-gray-100 p-4 mt-[5px] border rounded max-h-72 overflow-auto">
          {data2Content.length > 0
            ? renderTable(data2Content ?? null)
            : 'Upload a log file to view its contents here.'}
        </div>
      </div>
      <div className="">
        <h2 className="text-lg font-semibold mb-2 text-center">
          modification 2 - w/o img compression
        </h2>
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3V5H6a2 2 0 0 0-2 2v1H2V7a2 2 0 0 1 2-2zm2 10v3h6v-3h3a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3zm1-9h6v3H7V6zm7 5v1H6V8h8zm-4 3h2v2h-2v-2z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="mt-2">Select File</span>
          <input
            type="file"
            accept=".log, .txt, .json"
            className="hidden"
            onChange={(e) => handleFileUpload(e, setData3Content)}
          />
        </label>
        <>
          <DataAnalysis data={data3Content} />
        </>
        <div className="bg-gray-100 p-4 mt-[5px] border rounded max-h-72 overflow-auto">
          {data3Content.length > 0
            ? renderTable(data3Content ?? null)
            : 'Upload a log file to view its contents here.'}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Test7;
