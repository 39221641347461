import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons'; // Import the copy icon

const GenerateSHA256Hash = () => {
  const [files, setFiles] = useState<any>([]);
  const [hashes, setHashes] = useState<any>([]);
  const [copiedHash, setCopiedHash] = useState<string | null>(null); // To track copied hash

  // Handle file selection
  const handleFileChange = (e: any) => {
    setFiles(e.target.files); // Store selected files
  };

  // Function to read and hash file
  const generateHash = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        let fileContent = event.target.result;
        let hash;

        // Handle JSON files
        if (file.name.endsWith('.json')) {
          try {
            // Parse the JSON and then hash the stringified version
            const parsedJson = JSON.parse(fileContent);
            hash = CryptoJS.SHA256(JSON.stringify(parsedJson)).toString(CryptoJS.enc.Hex);
          } catch (error) {
            reject(`Error parsing JSON file: ${file.name}`);
          }
        }
        // Handle CSV or JSONL files
        else if (file.name.endsWith('.csv') || file.name.endsWith('.jsonl')) {
          // Treat them as plain text and hash the entire content
          hash = CryptoJS.SHA256(fileContent).toString(CryptoJS.enc.Hex);
        } else {
          reject(`Unsupported file type: ${file.name}`);
        }

        resolve({ fileName: file.name, hash });
      };

      reader.onerror = (error) => reject(`Error reading file: ${file.name}`);

      // Read the file as text
      reader.readAsText(file);
    });
  };

  // Process files and generate hashes
  const processFiles = async () => {
    const hashPromises = Array.from(files).map(generateHash);
    try {
      const results = await Promise.all(hashPromises);
      setHashes(results); // Set the results (file names and their hashes)
    } catch (error) {
      console.error('Error generating hashes:', error);
    }
  };

  // Copy hash to clipboard
  const copyToClipboard = (hash: string) => {
    navigator.clipboard.writeText(hash);
    setCopiedHash(hash); // Track which hash was copied
    setTimeout(() => setCopiedHash(null), 2000); // Clear copied state after 2 seconds
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="w-full max-w-3xl p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">
          Generate SHA-256 Hash for Multiple Files
        </h2>

        <div className="flex flex-col md:flex-row items-center gap-4 mb-6">
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="block w-full text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
          <button
            onClick={processFiles}
            className="px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Generate Hashes
          </button>
        </div>

        {hashes.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-800 mb-4">Hashes:</h3>
            <ul className="space-y-2">
              {hashes.map((item: any) => (
                <li
                  key={item.fileName}
                  className="flex items-center justify-between p-4 bg-gray-100 border border-gray-200 rounded-md shadow-sm"
                >
                  <div>
                    <span className="font-medium text-gray-700">{item.fileName}:</span>
                    <span className="ml-2 text-gray-600 text-sm break-all">{item.hash}</span>
                  </div>
                  <button
                    onClick={() => copyToClipboard(item.hash)}
                    className="ml-4 p-2 text-gray-500 hover:text-indigo-600 focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faCopy} className="h-5 w-5" />
                  </button>
                </li>
              ))}
            </ul>
            {copiedHash && (
              <div className="mt-4 text-sm text-green-600">Hash copied to clipboard!</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateSHA256Hash;
