import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { api } from '../../../../../../plugins/axios';

export default function BrandSeedingsVer2() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [downExcelStatus, setDownExcelStatus] = useState(false);

  const prepareDataForExcel = (resultData: any, resultStats: any) => {
    // Prepare raw data for Sheet 1
    const rawData = [] as any;
    for (const yearMonth in resultData) {
      for (const query in resultData[yearMonth]) {
        const items = resultData[yearMonth][query];
        items.forEach((item: any) => {
          rawData.push({
            YearMonth: yearMonth,
            SearchQuery: query,
            CollectionDate: item.collectionDate,
            VideoUrl: item.videoUrl,
            ConvertedLikeCount: item.convertedLikeCount,
            ConvertedCommentCount: item.convertedCommentCount,
            ConvertedVideoCount: item.convertedVideoCount,
          });
        });
      }
    }

    // Prepare stats for Sheet 2
    const statsData = [];
    for (const yearMonth in resultStats) {
      for (const query in resultStats[yearMonth]) {
        const stats = resultStats[yearMonth][query];
        statsData.push({
          YearMonth: yearMonth,
          SearchQuery: query,
          AverageLikeCount: stats.averageLikeCount,
          AverageCommentCount: stats.averageCommentCount,
          AverageVideoCount: stats.averageVideoCount,
          TotalLikeCount: stats.totalLikeCount,
          TotalCommentCount: stats.totalCommentCount,
          TotalVideoCount: stats.totalVideoCount,
        });
      }
    }

    return { rawData, statsData };
  };

  const handleDownload = async () => {
    setDownExcelStatus(true);
    try {
      if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          alert('Start date cannot be greater than end date.');
          return;
        }
      }

      const response = await api.get(
        '/admin/tiktok/webcrape/post/brandseedings/stat/totalandaverage/monthandyear',
        {
          params: {
            startDate: startDate,
            endDate: endDate,
          },
        }
      );

      if (response?.status == 200) {
        if (response?.data?.data && response?.data?.stats) {
          const { rawData, statsData } = prepareDataForExcel(
            response?.data?.data,
            response?.data?.stats
          );
          // Create a worksheet from the modified data
          // const ws1 = XLSX.utils.json_to_sheet(rawData);
          const ws2 = XLSX.utils.json_to_sheet(statsData);

          // Create a new workbook with the data in a sheet
          const wb = XLSX.utils.book_new();
          // XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1'); // Adding the worksheet
          XLSX.utils.book_append_sheet(wb, ws2, 'Sheet1'); // Adding the worksheet

          // Write to Excel file and trigger download
          let fileName = `_`;
          if (startDate && endDate) {
            // If both start and end date are provided
            fileName += `${startDate}_to_${endDate}`;
          } else if (startDate) {
            // If only start date is provided
            fileName += `from_${startDate}`;
          } else if (endDate) {
            // If only end date is provided
            fileName += `until_${endDate}`;
          } else {
            // If no dates are provided, indicate entire period
            fileName += 'entire_period';
          }

          XLSX.writeFile(wb, `${fileName}.xlsx`);
        } else {
          alert('empty: no data for given conditions');
        }
      } else {
        alert('error');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setTimeout(() => {
        setDownExcelStatus(false);
      }, 1000);
    }
    // setTimeout(() => {
    //   setDownExcelStatus(false);
    // }, 1000);
  };

  return (
    <div>
      <div className="space-y-6 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0">
        {/* Start Date */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* End Date */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {/* Download Button */}
        <div className="flex justify-center sm:col-span-2">
          <button
            onClick={handleDownload}
            disabled={downExcelStatus}
            className={`px-6 py-3 w-full sm:w-auto font-medium text-white rounded-md shadow-md ${
              downExcelStatus
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500'
            }`}
          >
            Download Excel
          </button>
        </div>
      </div>
    </div>
  );
}
