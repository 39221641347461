import React, { useEffect, useState } from 'react';
import { Test4Posts } from './Test4Posts';

import { v4 as uuidv4 } from 'uuid';

import * as XLSX from 'xlsx';

declare global {
  interface Window {
    gc: any;
  }
}

const ProgressBar = ({ progress, setProgress, startStatus }: any) => {
  return (
    <div className="w-full max-w-xs mx-auto mt-10">
      <div className="relative pt-1">
        <div className="flex  gap-[5px] mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
              Progress
            </span>
          </div>
          {startStatus && (
            <div className="flex justify-center items-center space-x-2">
              <div className="w-3 h-3 bg-pink-500 animate-spin rounded-sm"></div>
              <div className="w-3 h-3 bg-pink-500 animate-spin delay-150 rounded-sm"></div>
              <div className="w-3 h-3 bg-pink-500 animate-spin delay-300 rounded-sm"></div>
            </div>
          )}
          <div className="text-right">
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
              {progress?.toFixed(2)}%
            </span>
          </div>
        </div>
        <div className="flex mb-2 items-center justify-between">
          <div className="relative pt-1 w-full">
            <div className="flex mb-2 items-center justify-between">
              {/* Container for the progress bar with grey background */}
              <div className="w-full bg-gray-300">
                {/* Dynamic progress bar with teal color */}
                <div
                  className="bg-teal-500"
                  style={{
                    width: `${progress}%`, // Dynamic width based on progress
                    height: '20px',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataAnalysis = ({ data }: any) => {
  const [confidenceInterval, setConfidenceInterval] = useState<[number, number] | null>(null);
  const calculateMetrics = () => {
    if (data?.length <= 0) {
      alert('There is no data');
      return;
    }

    let times = data.map((obj: any) => {
      return parseFloat(obj?.loadTime);
    });

    const nBootstrapSamples = 1000;
    const bootstrapSamples = [];

    for (let i = 0; i < nBootstrapSamples; i++) {
      const bootstrapSample = [];
      for (let j = 0; j < times.length; j++) {
        const randomIndex = Math.floor(Math.random() * times.length);
        bootstrapSample.push(times[randomIndex]);
      }
      bootstrapSamples.push(bootstrapSample);
    }

    const bootstrapMeans = bootstrapSamples.map((sample) => {
      const mean = sample.reduce((acc, value) => acc + value, 0) / sample.length;
      return mean;
    });

    const sortedMeans = bootstrapMeans.sort((a, b) => a - b);

    const alpha = 0.95;
    const lowerQuantile = (1 - alpha) / 2;
    const upperQuantile = 1 - lowerQuantile;
    const lowerIndex = Math.floor(lowerQuantile * nBootstrapSamples);
    const upperIndex = Math.floor(upperQuantile * nBootstrapSamples);
    const ci: [number, number] = [sortedMeans[lowerIndex], sortedMeans[upperIndex]];

    setConfidenceInterval(ci);
  };

  return (
    <>
      <div className="flex justify-center items-center flex-col mt-[10px]">
        {/* Button to trigger calculation */}
        <button
          onClick={calculateMetrics}
          className="bg-[black] text-[white] rounded-[7px] py-[5px] px-[5px]"
        >
          Calculate Bootstrap Confidence Interval
        </button>

        {/* Display confidence interval */}
        {confidenceInterval !== null && (
          <div>
            {/* Display Bootstrap Confidence Interval */}
            <p>
              <strong>Bootstrap Confidence Interval:</strong> [{confidenceInterval[0].toFixed(2)},{' '}
              {confidenceInterval[1].toFixed(2)}]{/* Explanation of Confidence Interval */}
              <span className="text-gray-500"></span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const Test4 = () => {
  const [progress, setProgress] = useState(0);

  const [activeTab, setActiveTab] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('tab') || 'regular';
  });

  const [startStatus, setStartStatus] = useState(false);

  const [key, setKey] = useState(0);

  const [dataForAnalysis, setDataForAnalysis] = useState<any>(null);

  const [dataAnalysisId, setDataAnalysisId] = useState<any>(uuidv4());

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const currentTab = params.get('tab') || 'regular';
    setActiveTab(currentTab);
  }, [window.location.search]);

  const reloadMultipleTimes = () => {
    console.log('reloadMultipleTimes');
    setStartStatus(true);

    try {
      localStorage.setItem('tips_test4_times_list', JSON.stringify([]));
      let interval = setInterval(() => {
        setKey((prev) => prev + 1);
        let tips_test4_times_list = localStorage.getItem('tips_test4_times_list');
        if (tips_test4_times_list) {
          if (JSON.parse(tips_test4_times_list)) {
            let parsedList = JSON.parse(tips_test4_times_list);
            setProgress((parsedList?.length / 500) * 100);
            if (parsedList?.length >= 500) {
              if (interval) {
                clearInterval(interval);
              }
              setStartStatus(false);
              setDataForAnalysis(
                JSON.parse(localStorage.getItem('tips_test4_times_list') || '[]')?.slice(0, 499)
              );
            }
          }
        } else {
          alert('tips_test4_times_list empty');
          // if (interval) {
          //   clearInterval(interval);
          // }
        }
      }, 200);
    } catch (e) {
      console.log('reloadMultipleTimes e', e);
    } finally {
      // setStartStatus(false);
      // if (interval) {
      //   clearInterval(interval);
      // }
    }
  };

  const downloadExcel = (sheetName: string, data: any[], excelName: string) => {
    try {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, `${excelName}.xlsx`);
    } catch (error: any) {
      console.error('Error generating or downloading Excel file:', error.message);
      alert('Error generating or downloading Excel file');
    }
  };

  // useEffect(() => {
  //   if ('memory' in performance) {
  //     console.log('Performance memory:', performance.memory);
  //   } else {
  //     console.log('performance.memory is not supported in this environment.');
  //   }
  // }, [performance, (performance as any)?.memory]);

  return (
    <div>
      <div className="max-w-md mx-auto mt-10">
        {/* Tab Buttons */}
        <div className="flex border-b border-gray-300">
          <a
            href="?tab=regular"
            className={`text-center px-4 py-2 transition duration-300 ${
              activeTab === 'regular'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-blue-500'
            }`}
          >
            Regular
          </a>
          <a
            href="?tab=woIO"
            className={`text-center px-4 py-2 transition duration-300 ${
              activeTab === 'woIO'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-blue-500'
            }`}
          >
            Without Intersection Observer
          </a>
          <a
            href="?tab=woIC"
            className={`text-center px-4 py-2 transition duration-300 ${
              activeTab === 'woIC'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-blue-500'
            }`}
          >
            Without Image Compression
          </a>
        </div>

        {/* Tab Content */}
        <div className="border border-gray-300 mt-4 bg-[#FFFFFF] rounded-md p-4 shadow-sm min-h-[600px] h-[50vh] w-full overflow-x-auto">
          {activeTab && <Test4Posts tabType={activeTab} key={key} />}
          {startStatus && (
            <div className="absolute inset-0 bg-gray-100 bg-opacity-75 flex justify-center items-center z-10">
              <span className="text-gray-600 font-medium">
                <ProgressBar
                  progress={progress}
                  setProgress={setProgress}
                  startStatus={startStatus}
                />
              </span>
            </div>
          )}
        </div>

        <div className="flex justify-between gap-[10px]">
          <button
            onClick={() => reloadMultipleTimes()}
            disabled={startStatus}
            className={`${
              startStatus ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500'
            } mt-5 float-right px-6 py-2 text-white font-semibold rounded-lg shadow-md`}
          >
            Start
          </button>
          {dataForAnalysis && dataForAnalysis?.length > 0 && dataAnalysisId && (
            <button
              onClick={() => downloadExcel('Sheet1', dataForAnalysis, 'test')}
              disabled={startStatus}
              className={`${
                startStatus ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500'
              } mt-5 float-right px-6 py-2 text-white font-semibold rounded-lg shadow-md`}
            >
              Download data (excel)
            </button>
          )}
        </div>
        {dataForAnalysis && dataForAnalysis?.length > 0 && dataAnalysisId && (
          <DataAnalysis data={dataForAnalysis} />
        )}
      </div>
    </div>
  );
};

export default Test4;
