import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

import { toast } from 'react-toastify';
import { Button } from '../../../../../../../../../components/Button';
import { Icon } from '../../../../../../../../../components/Icon';
import { api } from '../../../../../../../../../plugins/axios';

import { pipeline, env } from '@xenova/transformers';
import axios from 'axios';

import {
  faSortUp,
  faSortDown,
  faSortAmountAsc,
  faSortAmountDown,
  faSortAmountDesc,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { apiForTempSol } from '../../../../../../../../../tempSolution/apiForTempSol';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

const Modal = ({
  visible,
  onClose,

  step0Status,
  step0Progress,

  step1Status,
  step1Progress,

  requestSrcs,

  cancelProcessRef,
}: any) => {
  return (
    <div className={`modal fixed inset-0 z-50 ${visible ? '' : 'hidden'}`}>
      <div className="modal-overlay bg-gray-900 opacity-50 absolute inset-0"></div>
      <div className="modal-content relative z-[100] bg-white w-64 mx-auto mt-20 p-6 rounded-lg shadow-lg">
        <div className="flex justify-content items-center gap-[10px]">
          <p style={{ color: step0Status ? 'black' : 'gray' }}>step0</p>
          <p>-</p>
          <p style={{ color: step1Status ? 'black' : 'gray' }}>step1</p>
        </div>
        <div className="flex gap-[5px] justify-center items-center">
          <p className="text-center">
            {step0Status == true
              ? (step0Progress ?? 0)?.toFixed(2)
              : step1Status == true
              ? (step1Progress ?? 0)?.toFixed(2)
              : null}
            %
          </p>

          <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
        </div>

        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-center">
            <div className="w-64 bg-gray-200 rounded-full">
              <div
                className="rounded-full bg-blue-500"
                style={{
                  width: `${
                    step0Status == true ? step0Progress : step1Status == true ? step1Progress : null
                  }%`,
                  height: '8px',
                }}
              ></div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <button
              className="border py-[3px] px-[3px]"
              onClick={() => {
                try {
                  cancelProcessRef.current = true;

                  requestSrcs.forEach((item: any) => {
                    if (item) {
                      item.cancel('Component unmounted');
                    }
                  });
                } catch (e) {}
              }}
            >
              cancel process
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageSearch = () => {
  const [modalVisible, setModalVisible] = useState(false);

  let [step0Progress, setStep0Progress] = useState(0);
  let [step0Status, setStep0Status] = useState(false);

  let [step1Progress, setStep1Progress] = useState(0);
  let [step1Status, setStep1Status] = useState(false);

  let [step2Progress, setStep2Progress] = useState(0);
  let [step2Status, setStep2Status] = useState(false);

  const [cancelTokens, setCancelTokens] = useState<any>([]);

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const [searchInput, setSearchInput] = useState<any>(parsed?.q ?? '');

  const [vkTourDests, setVkTourDests] = useState<any>(null);

  const source1Ref = useRef<any>(null);
  const source2Ref = useRef<any>(null);

  const [cancelProcessBtn, setCancelProcessBtn] = useState(false);

  const cancelProcessRef = useRef<any>(false);

  const [continueProcessData, setContinueProcessData] = useState<any>(null);

  const [error1, setError1] = useState<any>(null);

  const [scoreValue, setScoreValue] = useState<any>(0.3);

  const handleScoreChange = (event: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue !== '') {
      setScoreValue(inputValue);
    }
  };

  const handleSearchInput = (e: any) => {
    try {
      setSearchInput(e.target.value);
    } catch (e) {}
  };

  const isNumber = (value: any) => typeof value === 'number';

  const handleIBSearch = async (imageNameData: any, imageUrlData: any, generatedTextData: any) => {
    try {
      setStep1Status(true);
      setStep1Progress(0);

      let lsData = localStorage.getItem('f4e10cb3-2994-4e3f-8cfc-159b37ac7a81');

      let generatedText = '';

      source1Ref.current = axios.CancelToken.source();

      let continueProcessDataCopy = null as any;

      const repeat1 = () => {
        try {
          let newData = {
            comp: [],
            chosen: [],
            createdAt: new Date(),
            generatedText: generatedTextData,
            imageUrl: imageUrlData,
          };
          continueProcessDataCopy = newData;
          setContinueProcessData(newData);
        } catch (e) {
          console.log('repeat1 e', e);
        }
      };

      const storeInLS = () => {
        try {
          if (continueProcessDataCopy) {
            localStorage.setItem(
              'f4e10cb3-2994-4e3f-8cfc-159b37ac7a81',
              JSON.stringify({ [imageNameData]: continueProcessDataCopy })
            );
          }
        } catch (e) {
          console.log('storeInLS e', e);
        }
      };

      if (lsData) {
        if (parsed?.continueProcess == 'y') {
          let parsedLsData = JSON.parse(lsData);
          let key = Object.keys(parsedLsData)[0];
          if (key == imageNameData) {
            continueProcessDataCopy = parsedLsData[key];
            setContinueProcessData(parsedLsData[key]);
            generatedText = parsedLsData[key]?.generatedText;
          } else {
            window.location.href =
              '/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests';
          }
        } else {
          generatedText = generatedTextData;
          repeat1();
        }
      } else {
        if (parsed?.continueProcess == 'y') {
          window.location.href =
            '/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests';
        } else {
          generatedText = generatedTextData;
          repeat1();
        }
      }

      source2Ref.current = axios.CancelToken.source();

      if (generatedText) {
        let vkTourDestsToComp;
        if (vkTourDests?.length > 0) {
          vkTourDestsToComp = vkTourDests;
        } else {
          let vkTourDestsRes = await api.get(
            '/admin/ai/community/image-search/vg2ic/text-based/vk-tour-dests/get-vk-tour-dests',
            { cancelToken: source2Ref.current.token }
          );
          if (
            vkTourDestsRes?.data?.vkTourDests &&
            vkTourDestsRes?.data?.vkTourDests[0] &&
            vkTourDestsRes?.data?.vkTourDests[0]?.length > 0
          ) {
            setVkTourDests(vkTourDestsRes?.data?.vkTourDests[0]);
            vkTourDestsToComp = vkTourDestsRes?.data?.vkTourDests[0];
          }
        }
        if (vkTourDestsToComp?.length > 0) {
          let filterComp;

          if (continueProcessDataCopy && continueProcessDataCopy?.comp) {
            filterComp = vkTourDestsToComp?.filter(
              (item: any) => !continueProcessDataCopy?.comp?.includes(item?.id)
            );
            filterComp = pickRandomItems(filterComp, filterComp?.length);
          } else {
            filterComp = pickRandomItems(vkTourDestsToComp, vkTourDestsToComp?.length);
          }

          loop1: for (let i = 0; i < filterComp?.length; i++) {
            if (cancelProcessRef.current == true) {
              break loop1;
            }
            let step1Count =
              continueProcessDataCopy && continueProcessDataCopy?.comp
                ? continueProcessDataCopy?.comp?.length
                : 0;

            console.log('step1Count', step1Count);

            setStep1Progress((step1Count / vkTourDestsToComp?.length) * 100);

            console.log('step1 progress', (step1Count / vkTourDestsToComp?.length) * 100 + '%');
            let itemToComp = filterComp[i];
            if (itemToComp?.images?.length > 0 && itemToComp?.images[0]?.descEng) {
              let simiRes = await findSimilarByallMiniLML6v2(
                generatedText,
                itemToComp?.images[0]?.descEng
              );

              console.log('simiRes', simiRes);
              if (isNumber(simiRes)) {
                if ((simiRes as any) >= scoreValue) {
                  if (continueProcessDataCopy) {
                    let modfiedData = {
                      ...continueProcessDataCopy,
                      comp: [...(continueProcessDataCopy?.comp as any), itemToComp?.id],
                      chosen: [
                        ...(continueProcessDataCopy?.chosen as any),
                        {
                          vkTourDestId: itemToComp?.id,
                          score: simiRes,
                        },
                      ],
                    };
                    continueProcessDataCopy = modfiedData;
                    setContinueProcessData(modfiedData);
                    storeInLS();
                  } else {
                    let newData = {
                      comp: [itemToComp?.id],
                      chosen: [
                        {
                          vkTourDestId: itemToComp?.id,
                          score: simiRes,
                        },
                      ],
                      createdAt: new Date(),
                      generatedText: generatedTextData,

                      imageUrl: imageUrlData,
                    };
                    continueProcessDataCopy = newData;
                    setContinueProcessData(newData);
                    storeInLS();
                  }
                } else {
                  if (continueProcessDataCopy) {
                    let modfiedData = {
                      ...continueProcessDataCopy,
                      comp: [...(continueProcessDataCopy?.comp as any), itemToComp?.id],
                    };
                    continueProcessDataCopy = modfiedData;
                    setContinueProcessData(modfiedData);
                    storeInLS();
                  } else {
                    let newData = {
                      comp: [itemToComp?.id],
                      chosen: [],
                      createdAt: new Date(),
                      generatedText: generatedTextData,

                      imageUrl: imageUrlData,
                    };
                    continueProcessDataCopy = newData;
                    setContinueProcessData(newData);
                    storeInLS();
                  }
                }
              } else if ((simiRes as any) < 0) {
                if (continueProcessDataCopy) {
                  let modfiedData = {
                    ...continueProcessDataCopy,
                    comp: [...(continueProcessDataCopy?.comp as any), itemToComp?.id],
                  };
                  continueProcessDataCopy = modfiedData;
                  setContinueProcessData(modfiedData);
                  storeInLS();
                } else {
                  let newData = {
                    comp: [itemToComp?.id],
                    chosen: [],
                    createdAt: new Date(),
                    generatedText: generatedTextData,

                    imageUrl: imageUrlData,
                  };
                  continueProcessDataCopy = newData;
                  setContinueProcessData(newData);
                  storeInLS();
                }
              } else {
                // toast.error(`${JSON.stringify(simiRes)}`);
                toast.error(`세션이 종료되었습니다. 계속 진행하시겠습니까?`);

                setError1('세션이 종료되었습니다. 계속 진행하시겠습니까?');
                break loop1;
              }
            }
          }
        } else {
          console.log('no vkTourDestsToComp');
        }
      } else {
        console.log('no generatedText');
      }

      if (continueProcessDataCopy) {
        localStorage.setItem(
          'f4e10cb3-2994-4e3f-8cfc-159b37ac7a81',
          JSON.stringify({ [imageNameData]: continueProcessDataCopy })
        );
      }
      setModalVisible(false);
    } catch (e) {
      console.log('e', e);
      setModalVisible(false);
    }
  };

  useEffect(() => {
    if (parsed?.continueProcess == 'y') {
      let lsData = localStorage.getItem('f4e10cb3-2994-4e3f-8cfc-159b37ac7a81');
      if (lsData) {
        let parsedLsData = JSON.parse(lsData);
        let key = Object.keys(parsedLsData)[0];

        if (parsed?.imageName && parsedLsData[key]?.imageUrl && parsedLsData[key]?.generatedText) {
          setImageUrl(parsedLsData[key]?.imageUrl);
          setChosenImageData({ name: parsed?.imageName });

          setError1(null);

          cancelProcessRef.current = false;

          setStep0Progress(0);
          setStep0Status(false);

          setModalVisible(true);

          handleIBSearch(
            parsed?.imageName,
            parsedLsData[key]?.imageUrl,

            parsedLsData[key]?.generatedText
          );
        } else {
          window.location.href =
            '/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests';
        }
      } else {
        window.location.href =
          '/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests';
      }
    }
  }, [search]);

  useEffect(() => {
    return () => {
      [source1Ref.current, source2Ref.current].forEach((item) => {
        if (item) {
          item.cancel('Component unmounted');
        }
      });
    };
  }, []);

  const handleTBSearchButton = (evt: any) => {
    // handleTBSearch();
  };

  const handleTBSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      // handleTBSearch();
    }
  };

  const pickRandomItems = (list: any, numItems: any) => {
    const shuffled = list.sort(() => Math.random() - 0.5);
    return shuffled.slice(0, numItems);
  };

  const findSimilarByallMiniLML6v2 = async (targetData: any, compData: any) => {
    try {
      console.log('findSimilarByallMiniLML6v2');
      // Create a feature-extraction pipeline with the specified model
      let extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');

      // Function to compute cosine similarity between two vectors
      const cosineSimilarity = (vectorA: any, vectorB: any) => {
        const dotProduct = vectorA.reduce(
          (acc: any, val: any, i: any) => acc + val * vectorB[i],
          0
        );
        const magnitudeA = Math.sqrt(vectorA.reduce((acc: any, val: any) => acc + val * val, 0));
        const magnitudeB = Math.sqrt(vectorB.reduce((acc: any, val: any) => acc + val * val, 0));
        return dotProduct / (magnitudeA * magnitudeB);
      };

      // Function to compute similarity between two sentences
      const computeSimilarity = async (sentence1: any, sentence2: any) => {
        // Compute sentence embeddings for both sentences
        const embeddings = await extractor([sentence1, sentence2], {
          pooling: 'mean',
          normalize: true,
        });

        // Calculate cosine similarity between the embeddings
        const similarityScore = await cosineSimilarity(
          embeddings.tolist()[0],
          embeddings.tolist()[1]
        );
        return similarityScore;
      };

      let score = await computeSimilarity(targetData, compData);

      if (extractor && extractor?.model) {
        extractor.model.dispose();
      }

      return score;
    } catch (e) {
      return e;
    }
  };

  // const [activeTab, setActiveTab] = useState(0);

  // const tabs = [{ label: '텍스트로 검색' }, { label: '이미지로 검색' }];

  const findVkTourDestDetail = (dataObj: any) => {
    try {
      if (vkTourDests?.length > 0) {
        let foundData = vkTourDests.find((item: any) => item?.id == dataObj?.vkTourDestId);

        return { ...foundData, score: dataObj?.score };
      } else return null;
    } catch (e) {
      return null;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    if (continueProcessData?.chosen?.length > 0) {
      setTotalPages(Math.ceil((continueProcessData?.chosen?.length ?? 0) / itemsPerPage));

      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        continueProcessData?.chosen?.length ?? 0
      );

      setCurrentItems(continueProcessData?.chosen?.slice(startIndex, endIndex));
    }
  }, [currentPage, continueProcessData]);

  const goToPage = (page: any) => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    try {
      setCurrentPage(page);
      const element = document.querySelector('.cd7d819f-f5d9-49c6-a2fb-155f90a08db9');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    } catch (e) {}
  };

  const [sortDataStatus, setSortDataStatus] = useState(false);

  const [currSortOption, setCurrSortOption] = useState<any>(null);

  const sortData = (propType: any, sortType: any) => {
    try {
      setSortDataStatus(true);

      if (continueProcessData?.chosen?.length > 0) {
        if (propType == 'score') {
          if (sortType == 'asc') {
            setCurrSortOption({ propType: 'score', sortType: 'asc' });
            const ascendingSorted = continueProcessData?.chosen.sort(
              (a: any, b: any) => a.score - b.score
            );

            setContinueProcessData((prev: any) => {
              return { ...prev, chosen: ascendingSorted };
            });
          } else {
            setCurrSortOption({ propType: 'score', sortType: 'desc' });

            const descendingSorted = continueProcessData?.chosen.sort(
              (a: any, b: any) => b.score - a.score
            );

            setContinueProcessData((prev: any) => {
              return { ...prev, chosen: descendingSorted };
            });
          }
        }
      }
      setSortDataStatus(false);
    } catch (e) {
      console.log('e', e);
      setSortDataStatus(false);
    }
  };

  const [imageUrl, setImageUrl] = useState<any>(null);
  const [chosenImageData, setChosenImageData] = useState<any>(null);

  const handleImageUpload = async (event: any) => {
    try {
      setError1(null);

      cancelProcessRef.current = false;

      setStep0Progress(0);
      setStep0Status(true);

      setModalVisible(true);

      const file = event.target.files[0];
      if (file) {
        setChosenImageData(file);

        setImageUrl(URL.createObjectURL(file));

        const reader = new FileReader();

        reader.onload = async () => {
          const uploadedImageBase64String = reader.result;

          if (uploadedImageBase64String) {
            let vitGpt2ImageCaptioningRes = await vitGpt2ImageCaptioning({
              base64: uploadedImageBase64String,
            });
            console.log('vitGpt2ImageCaptioningRes', vitGpt2ImageCaptioningRes);
            if (
              (vitGpt2ImageCaptioningRes as any)?.length > 0 &&
              (vitGpt2ImageCaptioningRes as any)[0] &&
              vitGpt2ImageCaptioningRes
            ) {
              console.log('vitGpt2ImageCaptioningRes success');

              let generatedText = (vitGpt2ImageCaptioningRes as any)[0]?.generated_text;

              if (
                file?.name &&
                URL.createObjectURL(file) &&
                uploadedImageBase64String &&
                generatedText
              ) {
                // console.log('file?.name', file?.name);

                // const filename = file?.name;
                // const lastDotIndex = filename.lastIndexOf('.');
                // const beforeLastDot = filename.substring(0, lastDotIndex);
                // const afterLastDot = filename.substring(lastDotIndex + 1);

                // console.log('Before the last dot:', beforeLastDot);
                // console.log('After the last dot:', afterLastDot);

                setStep0Progress(0);
                setStep0Status(false);

                handleIBSearch(
                  file?.name,
                  URL.createObjectURL(file),

                  generatedText
                );
              }
            } else {
              console.log('vitGpt2ImageCaptioningRes fail');
              toast.error(`세션이 종료되었습니다. 계속 진행하시겠습니까?`);
              setError1(`세션이 종료되었습니다. 계속 진행하시겠습니까?`);
              setModalVisible(false);
            }
          } else {
            console.log(`uploaded image's base64 is not provided`);
            setError1(`uploaded image's base64 is not provided`);
            setModalVisible(false);
          }
        };

        reader.readAsDataURL(file);
      } else {
        setModalVisible(false);
      }
    } catch (e) {
      setModalVisible(false);
    }
  };

  const failedAttemptsFromVitGpt2ImageCaptioning = [];
  const vitGpt2ImageCaptioning = async (imageData: any, maxRetries = 3) => {
    try {
      console.log('vitGpt2ImageCaptioning');

      let retries = 0;
      console.log('try', retries + 1);

      while (retries < maxRetries) {
        try {
          const captioner = await pipeline('image-to-text', 'Xenova/vit-gpt2-image-captioning');

          const response = await captioner(imageData?.base64);

          if (captioner && captioner?.model) {
            captioner.model.dispose();
          }

          if (response) {
            return response;
          } else {
            console.error(`Request failed`);
          }
        } catch (error) {
          console.error('Error during request:', error);
        }

        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      console.log('vitGpt2ImageCaptioning: could not work after 3 attemps');

      return { message: 'vitGpt2ImageCaptioning: could not work after 3 attemps' };
    } catch (e) {
      console.log('vitGpt2ImageCaptioning e: ', e);
      return e;
    }
  };

  // useEffect(()=> {})

  const checkNames = () => {
    try {
      let lsData = localStorage.getItem('f4e10cb3-2994-4e3f-8cfc-159b37ac7a81');
      if (lsData) {
        let parseLsData = JSON.parse(lsData);
        if (chosenImageData?.name == Object.keys(parseLsData)[0]) {
          return true;
        } else {
          return false;
        }
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <div>
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <Modal
          visible={modalVisible}
          step0Status={step0Status}
          step0Progress={step0Progress}
          step1Status={step1Status}
          step1Progress={step1Progress}
          // step3Status={step3Status}
          // step3Progress={step3Progress}
          requestSrcs={[source1Ref.current, source2Ref.current]}
          cancelProcessRef={cancelProcessRef}
        />
        <>
          <>
            <div className="cd7d819f-f5d9-49c6-a2fb-155f90a08db9">
              <div className="flex flex-col items-center jutify-center">
                <p className="my-[3px]">Set score for similarity {'>='}</p>
                <input
                  className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
                  type="text"
                  value={scoreValue}
                  onChange={handleScoreChange}
                  placeholder="Enter numbers only"
                />
              </div>
              <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
                <label htmlFor="fileInput" className="block text-center mb-4">
                  <span className="text-lg font-semibold text-gray-800">Click to upload image</span>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageUpload}
                  />
                </label>
                {imageUrl && (
                  <div className="flex justify-center max-h-[300px]">
                    <img
                      src={imageUrl}
                      alt="Uploaded Image"
                      className="max-w-full h-auto rounded-lg object-contain"
                    />
                  </div>
                )}

                {chosenImageData?.name && <p className="text-center">{chosenImageData?.name}</p>}
              </div>
            </div>

            {error1 && (
              <div className="w-full flex gap-[5px] justify-end items-center my-[10px]">
                <p className="text-[red]">{error1}</p>

                <Button
                  disabled={!checkNames()}
                  className={`border rounded-[2px] ${
                    !checkNames() ? 'cursor-not-allowed bg-gray-300' : ''
                  }`}
                  onClick={() => {
                    try {
                      window.location.href = `/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests?continueProcess=y&imageName=${
                        chosenImageData?.name ?? ''
                      }`;
                    } catch (e) {}
                  }}
                >
                  계속 진행
                </Button>
                <Button
                  className="border rounded-[2px]"
                  onClick={() => {
                    try {
                      window.location.href =
                        '/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests';
                    } catch (e) {}
                  }}
                >
                  재시작
                </Button>
              </div>
            )}
            <div className="flex gap-[5px]">
              <div>
                <div className="font-[700] text-center">Score</div>
                <div className="flex gap-[15px]">
                  <div
                    className="cursor-pointer p-[5px]"
                    onClick={() => {
                      sortData('score', 'asc');
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountAsc}
                      color={
                        currSortOption?.propType == 'score' && currSortOption?.sortType == 'asc'
                          ? 'red'
                          : 'black'
                      }
                      className="leading-6"
                    />
                  </div>
                  <div
                    className="cursor-pointer p-[5px]"
                    onClick={() => {
                      sortData('score', 'desc');
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDesc}
                      color={
                        currSortOption?.propType == 'score' && currSortOption?.sortType == 'desc'
                          ? 'red'
                          : 'black'
                      }
                      className="leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  try {
                    localStorage.removeItem('f4e10cb3-2994-4e3f-8cfc-159b37ac7a81');
                    toast.success('cleared old data from ls');
                  } catch (e) {}
                }}
                className="bg-[#FF6B6B] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
              >
                clear old data from ls
              </button>
            </div>

            {sortDataStatus ? (
              <div className="flex justify-center">
                <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                {currentItems?.length > 0 && vkTourDests?.length > 0 && (
                  <>
                    <ul className="mt-[5px]">
                      {currentItems.map((item: any, index) => (
                        <li key={item?.vkTourDestId} className="border py-[5px] px-[5px] mb-[5px]">
                          {findVkTourDestDetail(item) ? (
                            <div className="grid grid-cols-8 gap-10">
                              {/* <div >{findVkTourDestDetail(item)?.id}</div> */}
                              <div className="col-span-5">
                                <div className="font-[700]">
                                  Score:{' '}
                                  {findVkTourDestDetail(item)?.score &&
                                    findVkTourDestDetail(item)?.score?.toFixed(2)}
                                </div>
                                <div className="font-[700]">
                                  {findVkTourDestDetail(item)?.title}
                                </div>
                                <div>{findVkTourDestDetail(item)?.overview}</div>
                              </div>

                              <div className="col-span-3 max-w-[300px] w-full flex justify-center items-center">
                                {findVkTourDestDetail(item)?.images?.length > 0 && (
                                  <img
                                    className="w-full"
                                    src={`${findVkTourDestDetail(item)?.images[0]?.url ?? ''}`}
                                  />
                                )}
                              </div>
                            </div>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                    <div className="flex justify-between mt-4">
                      <button
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`py-2 px-4 rounded-md ${
                          currentPage === 1
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                        } transition-colors duration-300`}
                      >
                        Previous Page
                      </button>
                      <span className="text-lg">
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={() => {
                          goToPage(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                        className={`py-2 px-4 rounded-md ${
                          currentPage === totalPages
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                        } transition-colors duration-300`}
                      >
                        Next Page
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </>
      </div>
    </div>
  );
};

export default ImageSearch;
