import React, { useEffect, useState } from 'react';
import { api } from '../../../../../../plugins/axios';

export default function RecentStats(props: any) {
  const [selectedSearchQueries, setSelectedSearchQueries] = useState<any>([]);

  const [selectedTabTypes, setSelectedTabTypes] = useState<any>([]);

  const [recentStats, setRecentStats] = useState<any>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [getLatestStatus, setGetLatestStatus] = useState(false);

  const toggleModal = async () => {
    try {
    } catch (e) {
      console.log('toggleModal e', e);
    } finally {
      setIsModalOpen(!isModalOpen);
    }
  };

  const getLatestStats = async () => {
    setGetLatestStatus(true);

    try {
      let res = await api.get('/admin/tiktok/webcrape/post/stats/latest/searchquery', {
        params: {
          searchQueries: selectedSearchQueries.join(','),
          tabTypes: selectedTabTypes.join(','),
        },
      });
      if (res?.status == 200 && res?.data?.length > 0) {
        const sortedStats = res?.data.sort((a: any, b: any) => {
          if (a.searchQuery < b.searchQuery) return -1;
          if (a.searchQuery > b.searchQuery) return 1;
          return 0;
        });
        setRecentStats(sortedStats);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setGetLatestStatus(false);
    }
  };

  const toggleSelectionForSearchQueries = (item: any) => {
    setSelectedSearchQueries(
      (prev: any) =>
        prev.includes(item)
          ? prev.filter((selected: any) => selected !== item) // Remove if already selected
          : [...prev, item] // Add if not selected
    );
  };

  const toggleSelectionForTabTypes = (item: any) => {
    setSelectedTabTypes(
      (prev: any) =>
        prev.includes(item)
          ? prev.filter((selected: any) => selected !== item) // Remove if already selected
          : [...prev, item] // Add if not selected
    );
  };

  return (
    <div>
      <>
        {/* Button to open the modal */}
        <button
          onClick={toggleModal}
          className={`${'bg-blue-500 text-white'} px-4 py-2 rounded mb-4`}
        >
          {'Latest collection stats'}
        </button>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg w-11/12 md:w-1/2 max-h-screen p-6 relative">
              {/* Close Button */}
              <button
                onClick={toggleModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>

              <h2 className="text-2xl font-semibold mb-4 text-gray-800">Latest collection stats</h2>

              {/* Modal Body with Scrollable Content */}
              <div className="overflow-x-auto max-h-[400px] overflow-y-auto">
                <div className="grid grid-cols-3 gap-4 p-4">
                  <div className="col-span-2 flex flex-col">
                    <label className="text-sm font-medium text-gray-600 mb-1">Search Query:</label>
                    <div className="w-full">
                      {selectedSearchQueries.length > 0 && (
                        <div className="border rounded-md bg-[#f3e4f5] p-2 text-gray-700 shadow-md cursor-pointer">
                          {selectedSearchQueries.join(', ')}
                        </div>
                      )}
                      <div className="top-full left-0 right-0 mt-1 max-h-[150px] overflow-y-auto border rounded-md bg-white shadow-md z-10">
                        {props?.searchQueries.map((item: any) => (
                          <div
                            key={item}
                            onClick={() => toggleSelectionForSearchQueries(item)}
                            className={`p-2 cursor-pointer hover:bg-blue-100 ${
                              selectedSearchQueries.includes(item) ? 'bg-blue-500 text-white' : ''
                            }`}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-sm font-medium text-gray-600 mb-1">Tab Types:</label>
                    <div className="w-full">
                      {selectedTabTypes.length > 0 && (
                        <div className="border rounded-md bg-[#f3e4f5] p-2 text-gray-700 shadow-md cursor-pointer">
                          {selectedTabTypes.join(', ')}
                        </div>
                      )}
                      <div className="top-full left-0 right-0 mt-1 max-h-[150px] overflow-y-auto border rounded-md bg-white shadow-md z-10">
                        {props?.tabTypes.map((item: any) => (
                          <div
                            key={item}
                            onClick={() => toggleSelectionForTabTypes(item)}
                            className={`p-2 cursor-pointer hover:bg-blue-100 ${
                              selectedTabTypes.includes(item) ? 'bg-blue-500 text-white' : ''
                            }`}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className={`${
                    getLatestStatus ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'
                  } px-4 py-2 rounded transition mb-[7px] float-right`}
                  disabled={getLatestStatus}
                  onClick={() => getLatestStats()}
                >
                  Get Latest Stats
                </button>
                {getLatestStatus ? (
                  <>
                    <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
                  </>
                ) : (
                  <>
                    {recentStats?.length > 0 && (
                      <table className="table-auto w-full border-collapse border border-gray-200">
                        <thead>
                          <tr className="bg-gray-100 text-gray-700">
                            <th className="border border-gray-200 px-4 py-2 text-left">
                              searchQuery
                            </th>
                            <th className="border border-gray-200 px-4 py-2 text-left">tabType</th>
                            <th className="border border-gray-200 px-4 py-2 text-left">
                              latestDate
                            </th>
                            <th className="border border-gray-200 px-4 py-2 text-left">count</th>
                          </tr>
                        </thead>

                        <tbody>
                          {recentStats.map((stat: any, index: any) => (
                            <tr
                              key={stat.id}
                              className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                            >
                              <td className="border border-gray-200 px-4 py-2">
                                {stat.searchQuery}
                              </td>
                              <td className="border border-gray-200 px-4 py-2">{stat.tabType}</td>
                              <td className="border border-gray-200 px-4 py-2">
                                {stat.latestDate}
                              </td>
                              <td className="border border-gray-200 px-4 py-2">{stat.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
}
