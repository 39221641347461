import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Sidebar } from '../components/Sidebar';
import { AdminHeader } from './components/AdminHeader';
import { AdminMain } from './components/AdminMain';
import { Dropdown } from '../components/Dropdown';
import { Menu } from '../components/Menu';
import { SearchWord } from './pages/searchWord/SearchWord';
import { SearchWordAdd } from './pages/searchWord/SearchWordAdd';
import { SearchWordDetail } from './pages/searchWord/SearchWordDetail';
import { BannerList } from './pages/banner/BannerList';
import { BannerAdd } from './pages/banner/BannerAdd';
import { BannerDetail } from './pages/banner/BannerDetail';
import { AdminRoute } from '../components/AdminRoute';
import { DisplayAdd } from './pages/display/DisplayAdd';
import { DisplayDetail } from './pages/display/DisplayDetail';
import { DisplayList } from './pages/display/DisplayList';
import { Accordion } from './components/Accordion';
import { ProductsList } from './pages/products/ProductsList';
import { ProductAdd } from './pages/products/ProductAdd';
import { ProductDetail } from './pages/products/ProductDetail';
import { Notification } from './pages/notification/Notification';
import { ProductionList } from './pages/production/ProductionList';
import { ProductionAdd } from './pages/production/ProductionAdd';
import { ProductionDetail } from './pages/production/ProductionDetail';
import { CampingList } from './pages/camping/CampingList';
import { CampingAdd } from './pages/camping/CampingAdd';
import { CampingDetail } from './pages/camping/CampingDetail';
import { CampingZoneList } from './pages/camping/CampingZoneList';
import { CampingZoneAdd } from './pages/camping/CampingZoneAdd';
import { CampingZoneDetail } from './pages/camping/CampingZoneDetail';
import { CampingZoneSiteList } from './pages/camping/CampingZoneSiteList';
import { CampingZoneSiteAdd } from './pages/camping/CampingZoneSiteAdd';
import { CampingZoneSiteDetail } from './pages/camping/CampingZoneSiteDetail';

// Onda
import { Bookings as OndaBookigns } from './pages/onda/camping/Bookings';
import { default as OndaBookingDetail } from './pages/onda/camping/BookingDetail';
import { default as OndaEditorMain } from './pages/onda/camping/editor/Main';
import { Payments as OndaIamportPayments } from './pages/onda/camping/iamport/Payments';
import { Properties as OndaProperties } from './pages/onda/camping/property/Properties';
import { default as OndaPropertyDetail } from './pages/onda/camping/property/PropertyDetail';
import { Users as OndaBookingsByUsers } from './pages/onda/camping/bookings/users/Users';

// Cafe24 (Dayout commerce)
import { Collection1 as Cafe24UserDataCollection1 } from './pages/cafe24-user-data/collection1/Collection1';
import { Videos as Cafe24ProductDetailVideos } from './pages/cafe24/dayout/commerce/product-detail/video/Videos';
import { VideoAdd as Cafe24ProductDetailVideoAdd } from './pages/cafe24/dayout/commerce/product-detail/video/VideoAdd';
import { VideoDetail as Cafe24ProductDetailVideoDetail } from './pages/cafe24/dayout/commerce/product-detail/video/VideoDetail';
import { Users } from './pages/onda/camping/users/Users';
import UserDetail from './pages/onda/camping/users/UserDetail';

// Dayout commerce
import { default as DayoutCommerceNewProductsPriceList } from './pages/dayout/commerce/new-products/PriceList';

// Community
import { BannerList as CommunityBannerList } from './pages/boardingpass-project/community/banner/BannerList';
import { BannerAdd as CommunityBannerAdd } from './pages/boardingpass-project/community/banner/BannerAdd';
import { BannerDetail as CommunityBannerDetail } from './pages/boardingpass-project/community/banner/BannerDetail';
import { default as CommunityBannerCreateHtmlData } from './pages/boardingpass-project/community/banner/BannerCreateHtmlData';

import { default as CommunityTDCAccommodationAdd } from './pages/boardingpass-project/community/tdc-accommodation/TDCAccommodationAdd';
import { TDCAccommodationList as CommunityTDCAccommodationList } from './pages/boardingpass-project/community/tdc-accommodation/TDCAccommodationList';
import { default as CommunityTDCAccommodationDetail } from './pages/boardingpass-project/community/tdc-accommodation/TDCAccommodationDetail';

import { TDCAccommCampaignList as CommunityTDCAccommCampaignList } from './pages/boardingpass-project/community/tdc-accommodation/campaign/TDCAccommCampaignList';
import { TDCAccommCampaignDetail as CommunityTDCAccommCampaignDetail } from './pages/boardingpass-project/community/tdc-accommodation/campaign/TDCAccommCampaignDetail';
import { TDCAccommCampaignAdd as CommunityTDCAccommCampaignAdd } from './pages/boardingpass-project/community/tdc-accommodation/campaign/TDCAccommCampaignAdd';

import { CreatePostHtmlV1_0 as CommunityCreatePostHtmlV1_0 } from './pages/boardingpass-project/community/post/CreatePostHtmlV1_0';

// AI
import Test from './pages/boardingpass-project/community/ai/Test';
import Test2 from './pages/boardingpass-project/community/ai/Test2';

import { default as AiTests } from './pages/boardingpass-project/community/ai/tests/Tests';

import { default as AiDataModify } from './pages/boardingpass-project/community/ai/data-modification/DataModify';
import { default as AiRecommendAllMiniLML6v2BookmarkCommunityPost } from './pages/boardingpass-project/community/ai/recommendation/all-MiniLM-L6-v2/bookmark/CommunityPost';
import { default as AiRecommendAllMiniLML6v2SearchHistoryCommunityPost } from './pages/boardingpass-project/community/ai/recommendation/all-MiniLM-L6-v2/search-history/CommunityPost';
import { default as AiRecommendAllMiniLML6v2JourneyLog } from './pages/boardingpass-project/community/ai/recommendation/all-MiniLM-L6-v2/journey-log/JourneyLog';
import { default as AiImageProcessVG2ICDescVKTourDest } from './pages/boardingpass-project/community/ai/image-process/vit-gpt2-image-captioning/description/VKTourDest';
import { default as AiImageSearchVG2ICTextBasedImageSearch } from './pages/boardingpass-project/community/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests/ImageSearch';
import { default as AiImageSearchVG2ICImageBasedImageSearch } from './pages/boardingpass-project/community/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests/ImageSearch';
import { default as AiGaDataProcessCommunityPostCreateData } from './pages/boardingpass-project/community/ai/ga/data-process/community-post/CreateData';
import { default as AiGaDataProcessCommunityPostViewData } from './pages/boardingpass-project/community/ai/ga/data-process/community-post/ViewData';

// Amazon
import { default as AmazonWebscrapeProductList } from './pages/boardingpass-project/amazon/webscrape/product/ProductList';
import { default as AmazonWebscrapeProductPriceReviewTracking } from './pages/boardingpass-project/amazon/webscrape/product/ProductPriceReviewTracking';

// Tiktok
import { default as TiktokWebscrapePostList } from './pages/boardingpass-project/tiktok/webscrape/post/PostList';
import { default as TiktokWebscrapePostBrandSeedingsVer1 } from './pages/boardingpass-project/tiktok/webscrape/post/BrandSeedingsVer1';
import { default as TiktokWebscrapePostBrandSeedingsVer2 } from './pages/boardingpass-project/tiktok/webscrape/post/BrandSeedingsVer2';

// Tips tests
import { default as TipsTest1 } from './pages/boardingpass-project/tips/tests/Test1';
import { default as TipsTest2 } from './pages/boardingpass-project/tips/tests/Test2';
import { default as TipsTest3 } from './pages/boardingpass-project/tips/tests/Test3';
import { default as TipsTest4 } from './pages/boardingpass-project/tips/tests/Test4';
import { default as TipsGenerateSHA256Hash } from './pages/boardingpass-project/tips/tests/GenerateSHA256Hash';

import { adminTokenState, meState } from '../ridge/ridge';

export const Admin = () => {
  const me = meState.useValue();

  const permissionMap = {
    'only-internal-admins': ['admin@admin.com', 'service@boardingpass.kr'],
    'all-admins': ['admin@admin.com', 'service@boardingpass.kr', 'guest1@boardingpass.kr'],
  } as any;

  function hasPermission(email: any, permission: any) {
    return permissionMap[permission].includes(email);
  }

  return (
    <div className="h-screen flex">
      <Sidebar>
        <Sidebar.Title>Admin</Sidebar.Title>
        <Sidebar.Menu>
          {hasPermission(me?.email, 'only-internal-admins') && (
            <>
              <Accordion
                title="상품관리"
                dataType="MENU"
                data={[{ text: '상품목록', to: '/admin/products' }]}
              />
              <Accordion
                title="캠핑관리"
                dataType="MENU"
                data={[{ text: '캠핑장 목록', to: '/admin/camping' }]}
              />
              <Accordion
                title="캠핑관리 (온다)"
                dataType="MENU"
                data={[
                  { text: '예약 목록', to: '/admin/v2/camping/bookings' },
                  { text: '아임포트 결제 내역', to: '/admin/v2/camping/iamport/payments' },
                  { text: '에디터 (메인)', to: '/admin/v2/camping/editor/main' },
                  { text: '숙소 리스트', to: '/admin/v2/camping/properties' },
                  { text: '예약 유저 리스트', to: '/admin/v2/camping/bookings/get-data/users' },
                  { text: '전체 유저 리스트', to: '/admin/v2/camping/get-data/users' },
                ]}
              />
              <Accordion
                title="스토어 관리"
                dataType="MENU"
                data={[
                  { text: '인기검색어 관리', to: '/admin/word' },
                  { text: '기획전 관리', to: '/admin/display' },
                  { text: '배너 관리', to: '/admin/banner' },
                  { text: '알림', to: '/admin/notification' },
                  { text: '프로덕션관리', to: '/admin/production' },
                ]}
              />
              <Accordion
                title="카페24 관리 (데이아웃 - 커머스)"
                dataType="MENU"
                data={[
                  { text: 'User Data Collection1', to: '/admin/cafe24-user-data/collection1' },
                  {
                    text: '상품 상세 페이지 동영상',
                    to: '/admin/cafe24/dayout/commerce/product-detail/videos',
                  },
                ]}
              />
              <Accordion
                title="데이아웃 - 커머스"
                dataType="MENU"
                data={[
                  {
                    text: '최저가 가격 찾기',
                    to: '/admin/dayout/commerce/new-products/price-list',
                  },
                ]}
              />
              <Accordion
                title="커뮤니티"
                dataType="MENU"
                data={[
                  { text: '배너관리', to: '/admin/community/banners' },
                  { text: '트립닷컴숙소리스트', to: '/admin/community/tdc-accommodations' },
                  {
                    text: '트립닷컴숙소캠페인리스트',
                    to: '/admin/community/tdc-accommodation/campaigns',
                  },
                  {
                    text: '포스트 html V1.0',
                    to: '/admin/community/post/create/html/v1.0',
                  },
                ]}
              />
              <Accordion
                title="인공지능"
                dataType="MENU"
                data={[
                  {
                    text: 'recommend_all-MiniLM-L6-v2_bookmark_community_post',
                    to: '/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post',
                  },
                  {
                    text: 'recommend_all-MiniLM-L6-v2_search_history_community_post',
                    to: '/admin/ai/recommendation/all-MiniLM-L6-v2/search-history/community-post',
                  },
                  {
                    text: 'image_process_vit-gpt2-image-captioning_vk_tour_dest',
                    to: '/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest',
                  },
                  {
                    text: 'image_search_vit-gpt2-image-captioning_text_based_vk_tour_dests',
                    to: '/admin/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests',
                  },
                  {
                    text: 'image_search_vit-gpt2-image-captioning_image_based_vk_tour_dests',
                    to: '/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests',
                  },
                  {
                    text: 'ga_data_process_community_post_create_data',
                    to: '/admin/ai/ga/data-process/community-post/create-data',
                  },
                  {
                    text: 'ga_data_process_community_post_view_data',
                    to: '/admin/ai/ga/data-process/community-post/view-data',
                  },
                  { text: 'ai tests', to: '/admin/ai/tests' },

                  { text: '테스트', to: '/admin/ai/test' },
                  { text: '테스트2', to: '/admin/ai/test2' },
                ]}
              />
              <Accordion
                title="아마존"
                dataType="MENU"
                data={[
                  { text: '상품 리스트 (크롤링)', to: '/admin/amazon/webcrape/product' },
                  {
                    text: '상품 가격, 리뷰 트래킹 (크롤링)',
                    to: '/admin/amazon/webcrape/product/price-review-tracking',
                  },
                ]}
              />
              <Accordion
                title="틱톡"
                dataType="MENU"
                data={[
                  { text: '틱톡 리스트 (크롤링)', to: '/admin/tiktok/webcrape/post' },
                  {
                    text: '틱톡 (크롤링) 월 브랜드 시딩 수',
                    to: '/admin/tiktok/webcrape/post/brandseedings/monthandyear',
                  },
                  {
                    text: '틱톡 (크롤링) 월 브랜드 시딩 평균, 총합',
                    to: '/admin/tiktok/webcrape/post/brandseedings/stat/totalandaverage/monthandyear',
                  },

                  // {
                  //   text: '상품 가격, 리뷰 트래킹 (크롤링)',
                  //   to: '/admin/amazon/webcrape/product/price-review-tracking',
                  // },
                ]}
              />
            </>
          )}
          {hasPermission(me?.email, 'all-admins') && (
            <Accordion
              title="팁스 테스트"
              dataType="MENU"
              data={[
                { text: 'Test1', to: '/admin/tips/test1' },
                { text: 'Test2', to: '/admin/tips/test2' },
                { text: 'Test3', to: '/admin/tips/test3' },
                { text: 'Test4', to: '/admin/tips/test4' },
                { text: 'GenerateSHA256Hash', to: '/admin/tips/generateSHA256Hash' },

                // {
                //   text: '상품 가격, 리뷰 트래킹 (크롤링)',
                //   to: '/admin/amazon/webcrape/product/price-review-tracking',
                // },
              ]}
            />
          )}
        </Sidebar.Menu>
      </Sidebar>

      <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
        <AdminHeader />
        <AdminMain>
          <Switch>
            <AdminRoute path="/admin/banner/add" component={BannerAdd} />
            <AdminRoute path="/admin/banner/:id" component={BannerDetail} />
            <AdminRoute path="/admin/banner" component={BannerList} />
            <AdminRoute path="/admin/word/add" component={SearchWordAdd} />
            <AdminRoute path="/admin/word/:id" component={SearchWordDetail} />
            <AdminRoute path="/admin/word" component={SearchWord} />
            <AdminRoute path="/admin/products/add" component={ProductAdd} />
            <AdminRoute path="/admin/products/:id" component={ProductDetail} />
            <AdminRoute path="/admin/products" component={ProductsList} />
            <AdminRoute path="/admin/display/add" component={DisplayAdd} />
            <AdminRoute path="/admin/display/:id" component={DisplayDetail} />
            <AdminRoute path="/admin/display" component={DisplayList} />
            <AdminRoute path="/admin/notification" component={Notification} />
            <AdminRoute path="/admin/production/add" component={ProductionAdd} />
            <AdminRoute path="/admin/production/:id" component={ProductionDetail} />
            <AdminRoute path="/admin/production" component={ProductionList} />
            <AdminRoute
              path="/admin/camping/:campingId/zones/:zoneId/sites/add"
              component={CampingZoneSiteAdd}
            />
            <AdminRoute
              path="/admin/camping/:campingId/zones/:zoneId/sites/:siteId"
              component={CampingZoneSiteDetail}
            />
            <AdminRoute
              path="/admin/camping/:campingId/zones/:zoneId/sites"
              component={CampingZoneSiteList}
            />
            <AdminRoute path="/admin/camping/:campingId/zones/add" component={CampingZoneAdd} />
            <AdminRoute
              path="/admin/camping/:campingId/zones/:zoneId"
              component={CampingZoneDetail}
            />
            <AdminRoute path="/admin/camping/:campingId/zones" component={CampingZoneList} />
            <AdminRoute path="/admin/camping/add" component={CampingAdd} />
            <AdminRoute path="/admin/camping/:id" component={CampingDetail} />
            <AdminRoute path="/admin/camping" component={CampingList} />
            {/* Cafe24 (dayout commerce) */}
            <AdminRoute
              path="/admin/cafe24-user-data/collection1"
              component={Cafe24UserDataCollection1}
            />
            <AdminRoute
              path="/admin/cafe24/dayout/commerce/product-detail/videos/add"
              component={Cafe24ProductDetailVideoAdd}
            />
            <AdminRoute
              path="/admin/cafe24/dayout/commerce/product-detail/videos/:id"
              component={Cafe24ProductDetailVideoDetail}
            />
            <AdminRoute
              path="/admin/cafe24/dayout/commerce/product-detail/videos"
              component={Cafe24ProductDetailVideos}
            />
            {/* Dayout commerce */}
            <AdminRoute
              path="/admin/dayout/commerce/new-products/price-list"
              component={DayoutCommerceNewProductsPriceList}
            />
            {/* Onda */}
            <AdminRoute
              path="/admin/v2/camping/bookings/get-data/users/detail"
              component={UserDetail}
            />
            <AdminRoute
              path="/admin/v2/camping/bookings/get-data/users"
              component={OndaBookingsByUsers}
            />
            <AdminRoute path="/admin/v2/camping/get-data/users" component={Users} />
            <AdminRoute path="/admin/v2/camping/bookings/:id" component={OndaBookingDetail} />
            <AdminRoute path="/admin/v2/camping/bookings" component={OndaBookigns} />
            <AdminRoute path="/admin/v2/camping/iamport/payments" component={OndaIamportPayments} />
            <AdminRoute path="/admin/v2/camping/editor/main" component={OndaEditorMain} />
            <AdminRoute path="/admin/v2/camping/properties/:id" component={OndaPropertyDetail} />
            <AdminRoute path="/admin/v2/camping/properties" component={OndaProperties} />
            {/* Community */}
            <AdminRoute
              path="/admin/community/banners/create-html-data"
              component={CommunityBannerCreateHtmlData}
            />
            <AdminRoute path="/admin/community/banners/add" component={CommunityBannerAdd} />
            <AdminRoute path="/admin/community/banners/:id" component={CommunityBannerDetail} />
            <AdminRoute path="/admin/community/banners" component={CommunityBannerList} />
            <AdminRoute
              path="/admin/community/tdc-accommodations/add"
              component={CommunityTDCAccommodationAdd}
            />
            <AdminRoute
              path="/admin/community/tdc-accommodations/:accommId"
              component={CommunityTDCAccommodationDetail}
            />
            <AdminRoute
              path="/admin/community/tdc-accommodations"
              component={CommunityTDCAccommodationList}
            />
            <AdminRoute
              path="/admin/community/tdc-accommodation/campaigns/add"
              component={CommunityTDCAccommCampaignAdd}
            />
            <AdminRoute
              path="/admin/community/tdc-accommodation/campaigns/:campaignId"
              component={CommunityTDCAccommCampaignDetail}
            />
            <AdminRoute
              path="/admin/community/tdc-accommodation/campaigns"
              component={CommunityTDCAccommCampaignList}
            />
            <AdminRoute
              path="/admin/community/post/create/html/v1.0"
              component={CommunityCreatePostHtmlV1_0}
            />
            {/* AI */}
            <AdminRoute path="/admin/ai/tests" component={AiTests} />
            <AdminRoute path="/admin/ai/test" component={Test} />
            <AdminRoute path="/admin/ai/test2" component={Test2} />
            <AdminRoute
              path="/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post"
              component={AiRecommendAllMiniLML6v2BookmarkCommunityPost}
            />
            <AdminRoute
              path="/admin/ai/recommendation/all-MiniLM-L6-v2/search-history/community-post"
              component={AiRecommendAllMiniLML6v2SearchHistoryCommunityPost}
            />
            <AdminRoute
              path="/admin/ai/recommendation/all-MiniLM-L6-v2/journey-log"
              component={AiRecommendAllMiniLML6v2JourneyLog}
            />
            <AdminRoute
              path="/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest"
              component={AiImageProcessVG2ICDescVKTourDest}
            />
            <AdminRoute
              path="/admin/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests"
              component={AiImageSearchVG2ICTextBasedImageSearch}
            />
            <AdminRoute
              path="/admin/ai/image-search/vit-gpt2-image-captioning/image-based/vk-tour-dests"
              component={AiImageSearchVG2ICImageBasedImageSearch}
            />
            <AdminRoute
              path="/admin/ai/ga/data-process/community-post/create-data"
              component={AiGaDataProcessCommunityPostCreateData}
            />
            <AdminRoute
              path="/admin/ai/ga/data-process/community-post/view-data"
              component={AiGaDataProcessCommunityPostViewData}
            />

            {/* Amazon  */}
            <AdminRoute
              path="/admin/amazon/webcrape/product/price-review-tracking"
              component={AmazonWebscrapeProductPriceReviewTracking}
            />
            <AdminRoute
              path="/admin/amazon/webcrape/product"
              component={AmazonWebscrapeProductList}
            />

            {/* Tiktok */}
            <AdminRoute
              path="/admin/tiktok/webcrape/post/brandseedings/monthandyear"
              component={TiktokWebscrapePostBrandSeedingsVer1}
            />
            <AdminRoute
              path="/admin/tiktok/webcrape/post/brandseedings/stat/totalandaverage/monthandyear"
              component={TiktokWebscrapePostBrandSeedingsVer2}
            />

            <AdminRoute path="/admin/tiktok/webcrape/post" component={TiktokWebscrapePostList} />

            {/* Tips tests */}
            <AdminRoute path="/admin/tips/test1" component={TipsTest1} />
            <AdminRoute path="/admin/tips/test2" component={TipsTest2} />
            <AdminRoute path="/admin/tips/test3" component={TipsTest3} />
            <AdminRoute path="/admin/tips/test4" component={TipsTest4} />
            <AdminRoute path="/admin/tips/generateSHA256Hash" component={TipsGenerateSHA256Hash} />

            {/* <Route path="/">
              <Redirect to="/admin" />
            </Route> */}
          </Switch>
        </AdminMain>
      </div>
    </div>
  );
};
