import React, { useEffect } from 'react';
import { api } from '../../../../../plugins/axios';

import { pipeline, env } from '@xenova/transformers';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

declare global {
  interface Window {
    // qna: any;
    // use: any;
    // tf: any;
  }
}

export default function Test() {
  const qnaModelFunc = async () => {
    try {
      // const passage = ``;
      // const question = '';
      // const model = await window?.qna?.load();
      // const answers = await model.findAnswers(question, passage);

      let pipe = await pipeline('sentiment-analysis');
      let out = await pipe('I like it');

      if (pipe && pipe?.model) {
        pipe.model.dispose();
      }

      const answerer = await pipeline(
        'question-answering',
        'Xenova/distilbert-base-uncased-distilled-squad'
      );
      const question = '';
      const context = ``;
      const output = await answerer(question, context);

      if (answerer && answerer?.model) {
        answerer.model.dispose();
      }

      const generator = await pipeline('text-generation', 'Xenova/llama2.c-stories15M');
      const text = 'Test 123';
      await generator(text);

      if (generator && generator?.model) {
        generator.model.dispose();
      }

      const captioner = await pipeline('image-to-text', 'Xenova/vit-gpt2-image-captioning');
      const url =
        'https://huggingface.co/datasets/Xenova/transformers.js-docs/resolve/main/cats.jpg';
      await captioner(url);

      if (captioner && captioner?.model) {
        captioner.model.dispose();
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    // qnaModelFunc();
  }, []);

  // useEffect(() => {
  //   try {
  //    api.post('/admin/community/posts/test/ai', { test: 'test' }).then((response: any) => {
  //     use.load().then((model: any) => {
  //       const givenParagraph = response?.data?.test?.translation;
  //       const dbParagraphs = [
  //         response?.data?.test2?.translation,
  //         response?.data?.test3?.translation,
  //         response.data?.test4?.translation,
  //         response?.data?.test5?.translation,
  //       ];
  //       model.embed([givenParagraph, ...dbParagraphs]).then((embeddings: any) => {
  //       for (let i = 1; i < embeddings.shape[0]; i++) {
  //       const similarityScore = window?.tf
  //       .matMul(
  //       embeddings.slice([0, 0], [1, embeddings.shape[1]]),
  //       embeddings.slice([i, 0], [1, embeddings.shape[1]]),
  //       false,
  //       true
  //       )
  //       .dataSync()[0];
  //       console.log(
  //       `Similarity between the given paragraph and paragraph ${i}: ${similarityScore}`
  //       );
  //       }
  //       });
  //     });
  //     });
  //   } catch (e) {
  //     console.log('e', e);
  //   }
  // }, []);

  return <div>Test</div>;
}
