import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { parse, stringify } from 'qs';
import { api } from '../../../../../../../../plugins/axios';
import { pipeline, env } from '@xenova/transformers';
import { toast } from 'react-toastify';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

const Modal = ({
  visible,
  onClose,
  step1Status,
  step1Progress,
  step2Status,
  step2Progress,
  step3Status,
  step3Progress,
}: any) => {
  return (
    <div className={`modal fixed inset-0 z-50 ${visible ? '' : 'hidden'}`}>
      <div className="modal-overlay bg-gray-900 opacity-50 absolute inset-0"></div>
      <div className="modal-content relative z-[100] bg-white w-64 mx-auto mt-20 p-6 rounded-lg shadow-lg">
        <div className="flex justify-content items-center gap-[10px]">
          <p style={{ color: step1Status ? 'black' : 'gray' }}>step1</p>
          <p>-</p>
          <p style={{ color: step2Status ? 'black' : 'gray' }}>step2</p>
          <p>-</p>
          <p style={{ color: step3Status ? 'black' : 'gray' }}>step3</p>
        </div>
        <div className="flex gap-[5px] justify-center items-center">
          <p className="text-center">
            {step1Status == true
              ? step1Progress
              : step2Status == true
              ? step2Progress
              : step3Status == true
              ? step3Progress
              : null}
            %
          </p>
          <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
        </div>

        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-center">
            <div className="w-64 bg-gray-200 rounded-full">
              <div
                className="rounded-full bg-blue-500"
                style={{
                  width: `${
                    step1Status == true ? step1Progress : step2Status == true ? step2Progress : null
                  }%`,
                  height: '8px',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommunityPost = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  const fetchCommunityBookmarkForPost = async (dataObj: any) => {
    try {
      let pageData = parsed?.page ?? 1;
      setIsLoading(true);
      const response = await api.get(
        `/admin/community/bookmark/pagination?type=communityPost&page=${pageData}&limit=${5}`
      );
      setIsLoading(false);
      return response?.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const { data: communityBookmarkForPost, isLoading: isCommunityBookmarkForPostLoading } = useQuery(
    // to identify each case
    `21ec2020-3aea-4069-a2dd-08002b30309d-page${parsed?.page ? parsed?.page : 1}`,
    fetchCommunityBookmarkForPost,
    {
      // enabled: authUserTokenObj?.token ? true : false,
      refetchOnWindowFocus: false,
    }
  );

  const handleNextPage = () => {
    if (communityBookmarkForPost?.items?.length > 0) {
      let pageData = (parsed?.page ? parseInt(parsed?.page as any) : 1) + 1;
      window.location.href = `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post?page=${pageData}`;
    }
  };

  const handlePrevPage = () => {
    if ((parsed?.page as any) > 1) {
      let pageData = (parsed?.page ? parseInt(parsed?.page as any) : 1) - 1;
      window.location.href = `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post?page=${pageData}`;
    }
  };

  const [inputPage, setInputPage] = useState(parsed?.page ?? 1);

  const handleInputChange = (eData: any) => {
    try {
      setInputPage(eData?.target?.value);
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleInputPage = () => {
    try {
      window.location.href = `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post?page=${inputPage}`;
    } catch (e) {
      console.log('e', e);
    }
  };

  let [step1Progress, setStep1Progress] = useState(0);
  let [step1Status, setStep1Status] = useState(false);

  let [step2Progress, setStep2Progress] = useState(0);
  let [step2Status, setStep2Status] = useState(false);

  let [bmData, setBmData] = useState<any>(null);
  let [vktdData, setVktdData] = useState<any>(null);

  const [bmCpsData, setBmCpsData] = useState<any>(null);

  const [appliedData, setAppliedData] = useState<any>({});

  const [scoreValue, setScoreValue] = useState<any>(0.3);

  const isNumber = (value: any) => typeof value === 'number';

  const handleScoreChange = (event: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue !== '') {
      setScoreValue(inputValue);
    }
  };

  const [startRangeValue, setStartRangeValue] = useState<any>(1);
  const [endRangeValue, setEndRangeValue] = useState<any>(1);

  const handleRangeChange = (event: any, typeData: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue !== '') {
      typeData == 'start' ? setStartRangeValue(inputValue) : setEndRangeValue(inputValue);
    }
  };

  const [step3Progress, setStep3Progress] = useState(0);
  const [step3Status, setStep3Status] = useState(false);

  const applyToEntireData = async (typeData: any) => {
    try {
      setModalVisible(true);

      setStep1Status(true);
      setStep1Progress(0);

      setStep2Progress(0);

      setStep3Progress(0);

      if (typeData == 'continue') {
      } else if (typeData == 'restart') {
        localStorage.removeItem('fd93b609292f4b928c3f6fd07f501e3b');
      }

      let page = 1;
      let limit = 3;
      let applyToEntireDataStatus = true;
      let currTotal = 0;
      let applyToEntireDataTotal = 0;
      let vkTourDestsToComp = [] as any;
      let bmCps = [] as any;

      while (applyToEntireDataStatus) {
        const applyToEntireDataRes = await api.get(
          `/admin/ai/community/recommendation/all-MiniLM-L6-v2/bookmark/community-post/get-community-posts?page=${page}&limit=${limit}&startCpId=${startRangeValue}&endCpId=${endRangeValue}`
        );

        if (
          page == 1 &&
          applyToEntireDataRes?.data?.vkTourDests &&
          applyToEntireDataRes?.data?.vkTourDests[0]
        ) {
          vkTourDestsToComp = applyToEntireDataRes?.data?.vkTourDests[0];
          setVktdData(applyToEntireDataRes?.data?.vkTourDests[0]);
        }

        if (applyToEntireDataRes?.data?.items?.length > 0) {
          bmCps = [...bmCps, ...(applyToEntireDataRes?.data?.items as any)];
          // for (let i = 0; i < applyToEntireDataRes?.data?.items?.length; i++) {
          //   let item = applyToEntireDataRes?.data?.items[i];
          // }
          setBmCpsData(bmCps);

          currTotal += applyToEntireDataRes?.data?.items?.length;
          if (page == 1) {
            applyToEntireDataTotal = applyToEntireDataRes?.data?.total;
          }
          setStep1Progress((currTotal / applyToEntireDataTotal) * 100);
          page++;
        } else {
          applyToEntireDataStatus = false;
          break;
        }
      }

      setStep1Progress(0);
      setStep1Status(false);

      //////////////////////////////////////////////////////////

      setStep2Status(true);

      loop1: for (let i = 0; i < bmCps?.length; i++) {
        console.log('i progress', (i / bmCps?.length) * 100 + '%');
        setStep2Progress((i / bmCps?.length) * 100);
        let cpObj = bmCps[i];

        let loop1Status = true;

        let checkStatus = true;
        let checkStatusLs = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b_1');
        if (checkStatusLs) {
          let parsed = JSON.parse(checkStatusLs);
          if (parsed && parsed[cpObj?.cp_id] && parsed[cpObj?.cp_id]?.comps) {
            checkStatus = parsed[cpObj?.cp_id]?.status;
          }
        }

        if (checkStatus) {
          loopwhile1: while (loop1Status) {
            let lsData1 = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b');
            if (lsData1) {
              lsData1 = JSON.parse(lsData1) as any;
              if (lsData1 && lsData1[cpObj?.cp_id] && lsData1[cpObj?.cp_id]?.length >= 5) {
                let trackComp = checkStatusLs;
                if (trackComp) {
                  let parsed = JSON.parse(trackComp);
                  if (parsed && parsed[cpObj?.cp_id] && parsed[cpObj?.cp_id]?.comps) {
                    parsed[cpObj?.cp_id].status = false;
                    localStorage.setItem(
                      'fd93b609292f4b928c3f6fd07f501e3b_1',
                      JSON.stringify({
                        ...parsed,
                      })
                    );
                  }
                }

                break loopwhile1;
              }
            }

            let tempExcl = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b_1');
            let filteredList = vkTourDestsToComp;
            if (tempExcl) {
              let parsed = JSON.parse(tempExcl);
              if (parsed && parsed[cpObj?.cp_id] && parsed[cpObj?.cp_id]?.comps?.length > 0) {
                filteredList = vkTourDestsToComp.filter(
                  (item: any) => !parsed[cpObj?.cp_id]?.comps.includes(item)
                );
              }
            }

            let randomItems = pickRandomItems(filteredList, 1000);
            loop2: for (let j = 0; j < randomItems?.length; j++) {
              let lsData2 = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b');
              if (lsData2) {
                lsData2 = JSON.parse(lsData2) as any;
                if (lsData2 && lsData2[cpObj?.cp_id] && lsData2[cpObj?.cp_id]?.length >= 5) {
                  break loop2;
                }
              }

              console.log('j progress', (j / randomItems?.length) * 100 + '%');
              let vktd = randomItems[j];

              let trackComp = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b_1');
              if (trackComp) {
                let parsed = JSON.parse(trackComp);
                if (parsed && parsed[cpObj?.cp_id]) {
                  // parsed[cpObj?.cp_id]['status'] == true || parsed[cpObj?.cp_id]['status'] ==
                  if (parsed[cpObj?.cp_id]?.comps) {
                    if (
                      parsed[cpObj?.cp_id]?.comps?.length >= vkTourDestsToComp?.length ||
                      parsed[cpObj?.cp_id]?.comps?.length >= 1000
                    ) {
                      parsed[cpObj?.cp_id].status = false;
                      localStorage.setItem(
                        'fd93b609292f4b928c3f6fd07f501e3b_1',
                        JSON.stringify({
                          ...parsed,
                        })
                      );
                    } else {
                      if (!parsed[cpObj?.cp_id].comps?.includes(vktd?.id)) {
                        parsed[cpObj?.cp_id].comps.push(vktd?.id);
                      }

                      localStorage.setItem(
                        'fd93b609292f4b928c3f6fd07f501e3b_1',
                        JSON.stringify({
                          ...parsed,
                        })
                      );
                    }
                  } else {
                    localStorage.setItem(
                      'fd93b609292f4b928c3f6fd07f501e3b_1',
                      JSON.stringify({
                        ...parsed,
                        [cpObj?.cp_id]: { status: true, comps: [vktd?.id] },
                      })
                    );
                  }
                } else {
                  localStorage.setItem(
                    'fd93b609292f4b928c3f6fd07f501e3b_1',
                    JSON.stringify({
                      ...parsed,
                      [cpObj?.cp_id]: { status: true, comps: [vktd?.id] },
                    })
                  );
                }
              } else {
                localStorage.setItem(
                  'fd93b609292f4b928c3f6fd07f501e3b_1',
                  JSON.stringify({
                    [cpObj?.cp_id]: { status: true, comps: [vktd?.id] },
                  })
                );
              }

              let simiRes = await findSimilarByallMiniLML6v2(
                vktd?.translatedOverview,
                cpObj?.translatedText
              );
              console.log('simiRes', simiRes);
              if (isNumber(simiRes)) {
                if ((simiRes as any) >= scoreValue && vktd?.images?.length > 0) {
                  let lsData3 = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b');
                  if (lsData3) {
                    lsData3 = JSON.parse(lsData3);
                    if (lsData3 && lsData3[cpObj?.cp_id]) {
                      if (lsData3[cpObj?.cp_id]?.length >= 0 && lsData3[cpObj?.cp_id]?.length < 5) {
                        if (
                          !(lsData3[cpObj?.cp_id] as any)?.find(
                            (objData: any) => objData?.vktdId == vktd?.id
                          )
                        ) {
                          localStorage.setItem(
                            'fd93b609292f4b928c3f6fd07f501e3b',
                            JSON.stringify({
                              ...(lsData3 as any),
                              [cpObj?.cp_id]: [
                                ...(lsData3[cpObj?.cp_id] as any),
                                { vktdId: vktd?.id, score: simiRes },
                              ],
                            })
                          );
                        }
                      } else {
                        break loop2;
                      }
                    } else {
                      localStorage.setItem(
                        'fd93b609292f4b928c3f6fd07f501e3b',
                        JSON.stringify({
                          ...(lsData3 as any),
                          [cpObj?.cp_id]: [{ vktdId: vktd?.id, score: simiRes }],
                        })
                      );
                    }
                  } else {
                    localStorage.setItem(
                      'fd93b609292f4b928c3f6fd07f501e3b',
                      JSON.stringify({ [cpObj?.cp_id]: [{ vktdId: vktd?.id, score: simiRes }] })
                    );
                  }
                }
              } else if ((simiRes as any) < 0) {
              } else {
                toast.error(`${JSON.stringify(simiRes)}`);
                break loop1;
              }
            }
          }
        }
      }

      let processedLs = localStorage.getItem('fd93b609292f4b928c3f6fd07f501e3b');
      if (processedLs) {
        let parsed = JSON.parse(processedLs);
        let keys = Object.keys(parsed);
        const paginateList = (list: any, page = 1, pageSize = 10) => {
          try {
            const startIndex = (page - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            return list.slice(startIndex, endIndex).map((key: any) => {
              return { [key]: parsed[key] };
            });
          } catch (e) {}
        };

        const itemsPerPage = 5;
        let currentPage = 1;

        // let currentPageItems = paginateList(keys, currentPage, itemsPerPage);
        let whileStatus = true;
        while (whileStatus) {
          setStep3Progress((page / Math.ceil(keys?.length / itemsPerPage)) * 100);

          let currentPageItems = paginateList(keys, currentPage, itemsPerPage);
          currentPage++;

          if (currentPageItems.length > 0) {
            await api.patch(
              '/admin/ai/community/recommendation/all-MiniLM-L6-v2/bookmark/community-post/all-users/apply',
              { data: currentPageItems }
            );
          } else {
            whileStatus = false;
            break;
          }
        }
      } else {
        toast.error('no processedLs');
      }

      setStep2Status(false);
      setStep2Progress(0);

      setStep3Status(false);
      setStep3Progress(0);

      setModalVisible(false);

      //  `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post/users/{userId}/apply`
    } catch (e) {}
  };

  const failedAttemptsFromBingTranslateApi = [];
  const bertQA = async (questionData: any, contextData: any, maxRetries = 3) => {
    try {
      console.log('bertQA');

      let retries = 0;
      console.log('try', retries + 1);

      while (retries < maxRetries) {
        try {
          const answerer = await pipeline(
            'question-answering',
            'Xenova/distilbert-base-uncased-distilled-squad'
          );
          const response = await answerer(questionData, contextData);

          if (answerer && answerer?.model) {
            answerer.model.dispose();
          }

          if (response) {
            return response;
          } else {
            console.error(`Request failed`);
          }
        } catch (error) {
          console.error('Error during request:', error);
        }

        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      console.log('bertQA: could not work after 3 attemps');

      return null;
    } catch (e) {
      console.log('bertQA e: ', e);
      return null;
    }
  };

  const findSimilarByallMiniLML6v2 = async (targetData: any, compData: any) => {
    try {
      console.log('findSimilarByallMiniLML6v2');
      // Create a feature-extraction pipeline with the specified model
      let extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');

      // Function to compute cosine similarity between two vectors
      const cosineSimilarity = (vectorA: any, vectorB: any) => {
        const dotProduct = vectorA.reduce(
          (acc: any, val: any, i: any) => acc + val * vectorB[i],
          0
        );
        const magnitudeA = Math.sqrt(vectorA.reduce((acc: any, val: any) => acc + val * val, 0));
        const magnitudeB = Math.sqrt(vectorB.reduce((acc: any, val: any) => acc + val * val, 0));
        return dotProduct / (magnitudeA * magnitudeB);
      };

      // Function to compute similarity between two sentences
      const computeSimilarity = async (sentence1: any, sentence2: any) => {
        // Compute sentence embeddings for both sentences
        const embeddings = await extractor([sentence1, sentence2], {
          pooling: 'mean',
          normalize: true,
        });

        // Calculate cosine similarity between the embeddings
        const similarityScore = await cosineSimilarity(
          embeddings.tolist()[0],
          embeddings.tolist()[1]
        );
        return similarityScore;
      };

      let score = await computeSimilarity(targetData, compData);

      if (extractor && extractor?.model) {
        extractor.model.dispose();
      }

      return score;
    } catch (e) {
      return e;
    }
  };

  const pickRandomItems = (list: any, numItems: any) => {
    const shuffled = list.sort(() => Math.random() - 0.5);
    return shuffled.slice(0, numItems);
  };

  return (
    <div className="52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc max-w-md">
      <h1 className="mb-[10px]">recommend_all-MiniLM-L6-v2_bookmark_community_post</h1>

      <Modal
        visible={modalVisible}
        step1Status={step1Status}
        step1Progress={step1Progress}
        step2Status={step2Status}
        step2Progress={step2Progress}
        step3Status={step3Status}
        step3Progress={step3Progress}
      />
      <div className="flex gap-[10px]">
        <button
          onClick={() => applyToEntireData('continue')}
          className="bg-[#FF6B6B] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Apply to the entire data - continue
        </button>
        <div>
          <p className="my-[3px]">Set score for similarity</p>

          <input
            className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
            type="text"
            value={scoreValue}
            onChange={handleScoreChange}
            placeholder="Enter numbers only"
          />
          <p className="my-[3px]">Set range for target data ids</p>
          <div className="flex items-center gap-[10px]">
            <input
              className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              type="text"
              value={startRangeValue}
              onChange={(e) => handleRangeChange(e, 'start')}
              placeholder="Enter numbers only"
            />
            <div> ~ </div>
            <input
              className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              type="text"
              value={endRangeValue}
              onChange={(e) => handleRangeChange(e, 'end')}
              placeholder="Enter numbers only"
            />
          </div>
        </div>

        <button
          onClick={() => {
            try {
              localStorage.removeItem('fd93b609292f4b928c3f6fd07f501e3b');
              localStorage.removeItem('fd93b609292f4b928c3f6fd07f501e3b_1');
              toast.success('cleared old data from ls');
            } catch (e) {}
          }}
          className="bg-[#FF6B6B] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          clear old data from ls
        </button>
      </div>
      {!isCommunityBookmarkForPostLoading ? (
        communityBookmarkForPost?.items?.length > 0 &&
        communityBookmarkForPost?.items?.map((item: any) => (
          <div key={item?.userId} className="border-b border-solid border-gray-300 py-[10px]">
            <div className="gap-[5px] pt-[5px]">
              {item?.userId && <p>userId: {item?.userId}</p>}
              {item?.totalCount >= 0 && <p>totalCount: {item?.totalCount}</p>}
            </div>

            <div className="flex gap-[5px] pt-[5px]">
              {/* <button
                onClick={handleNextPage}
                className="bg-[#4CAF50] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
              >
                Apply
              </button> */}
            </div>
            <style>
              {`
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc div,
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc img,
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc video,
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc iframe {
           max-width: 100%;
         }
        `}
            </style>
          </div>
        ))
      ) : (
        <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
      )}
      <div className="flex items-center justify-center space-x-4 py-[20px]">
        <button
          onClick={handlePrevPage}
          disabled={(parsed?.page as any) == 1}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Previous Page
        </button>
        <div className="flex gap-[5px] items-center">
          <span className="text-gray-700 font-semibold">Page</span>
          <input
            type="number"
            value={inputPage as any}
            onChange={(e) => handleInputChange(e)}
            //   onKeyPress={handleKeyPress}
            className="border border-gray-400 px-2 py-1 rounded focus:outline-none"
            placeholder="Enter page number"
          />
          <button
            onClick={handleInputPage}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Go
          </button>
        </div>
        <button
          onClick={handleNextPage}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

export default CommunityPost;
